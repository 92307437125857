import {
  Box,
  Flex,
  HStack,
  Text,
  Image,
  Button,
  VStack,
  Input,
  Center,
  InputGroup,
} from "@chakra-ui/react";
import partImg from "../../img/partilanding.png";
import { useNavigate } from "react-router-dom";
import UserRoutes from "../../pages/routes";
import landingbg from "../../img/landingbg.png";

const CraftingEvents = () => {
  const router = useNavigate();

  return (
    <>
      <Flex
        bg={"white"}
        pl={["0px", "0px", "30px", "60px"]}
        flexDirection={["column", "column", "row", "row"]}
        alignItems={"center"}
        justifyContent={["center", "center", "space-between", "space-between"]}
        color="#000000"
      >
        <Box pb={["40px", "50px", "60px", "60px"]}>
          <Box
            maxW={"600px"}
            px={["20px", "50px", "0px", "0px"]}
            pt={["150px", "150px", "30px", "30px"]}
            pb={["50px", "50px", "0px", "0px"]}
            bgImage={[landingbg, landingbg, "none", "none"]}
            backgroundSize={"cover"}
            backgroundPosition={"center top"}
            backgroundRepeat={"no-repeat"}
          >
            <Text
              textAlign={["left", "left", "left", "left"]}
              fontSize={["40px", "40px", "50px", "60px"]} fontFamily={'Roboto ,serif'} 
              fontWeight={"600"}
              
              color={["#FFFFFF", "#FFFFFF", "#000000", "#000000"]}
            >
              Crafting
            </Text>

            <Text
              textAlign={["left", "left", "left", "left"]}
              fontSize={["40px", "48px", "50px", "60px"]}
              fontFamily={'Roboto ,serif'} 
              mt={["-12px", "-15px", "-20px", "-20px"]}
              fontWeight={"600"}
              color={["#FFFFFF", "#FFFFFF", "#000000", "#000000"]}
            >
              Unforgettable
            </Text>
            <Text
              display={["block", "block", "none", "none"]}
              mt={["-12px", "-15px", "-20px", "-20px"]}
              textAlign={["left", "left", "left", "left"]}
              fontSize={["40px", "40px", "50px", "60px"]} fontFamily={'Roboto ,serif'} 
              fontWeight={"600"}
              color={["#FFFFFF", "#FFFFFF", "#000000", "#000000"]}
            >
              Events, One
            </Text>
            <Text
              display={["none", "none", "block", "block"]}
              mt={["-12px", "-15px", "-20px", "-20px"]}
              textAlign={["left", "left", "left", "left"]}
              fontSize={["40px", "40px", "50px", "60px"]} fontFamily={'Roboto ,serif'} 
              fontWeight={"600"}
              color={"#000000"}
            >
              Events, One Detail
            </Text>
            <Text
              display={["none", "none", "block", "block"]}
              textAlign={["left", "left", "left", "left"]}
              fontSize={["40px", "40px", "50px", "60px"]} fontFamily={'Roboto ,serif'} 
              mt={["-12px", "-15px", "-20px", "-20px"]}
              fontWeight={"600"}
              color={["#000000"]}
            >
              at a Time
            </Text>
            <Text
              display={["block", "block", "none", "none"]}
              textAlign={["left", "left", "left", "left"]}
              fontSize={["40px", "40px", "50px", "60px"]} fontFamily={'Roboto ,serif'} 
              mt={["-12px", "-15px", "-20px", "-20px"]}
              fontWeight={"600"}
              color={["#FFFFFF", "#FFFFFF", "#000000", "#000000"]}
            >
              Detail at a Time
            </Text>
          </Box>
          <Box
            maxW={"600px"}
            px={["20px", "40px", "0px", "0px"]}
            pt={["30px", "50px", "0px", "0px"]}
            pb={["80px", "80px", "0px", "0px"]}
          >
            <Text
              textAlign={["left", "left", "left", "left"]}
              fontSize={["15px", "16px", "16px", "18px"]}
              fontWeight={"400"}
              color={"#000000"}
              maxW={"500px"}
              fontFamily={"Inter"}
            >
              Welcome to PartiGram, where event planning becomes a joyous
              experience. Create, organize, and celebrate your special moments
              effortlessly. Get started now!
            </Text>
          </Box>
          <Flex
            mt={["0px", "0px", "40px", "50px"]}
            justifyContent={["center", "center", "flex-start", "flex-start"]}
            flexDirection={["column", "column", "row", "row"]}
            px={["20px", "40px", "0px", "0px"]}
            bottom={['24px','24px','unset','unset']}
          >
            <Button
              cursor="pointer"
              mr="8px"
              color={"white"}
              onClick={() => {
                router(UserRoutes.Login);
              }}
              bg={"#583A76"}
              fontSize={"14px"}
              fontWeight={"400"}
              height={'48px'}
              width={["100%", "100%", "120px", "120px"]}
              mb={['15px','20px','0px','0px']}
              _hover={{ bg: "white", color: "#583A76" }}
              bottom={''}
            >
              Login
            </Button>

            <Button
              cursor="pointer"
              colorScheme={"purple"}
              variant={"outline"}
              fontSize={"14px"}
              height={'48px'}
              fontWeight={"400"}
              onClick={() => {
                router(UserRoutes.Register);
              }}
              _hover={{ bg: "#583A76", color: "white" }}
              width={["100%", "100%", "120px", "120px"]}
            >
              Reigster
            </Button>
          </Flex>
        </Box>
        <Box
          display={["none", "none", "block", "block"]}
          width={"50%"}
          height={"100vh"}
          bgImage={partImg}
          backgroundSize={"cover"}
          backgroundPosition={"center top"}
          backgroundRepeat={"no-repeat"}
        ></Box>
      </Flex>
    </>
  );
};

export default CraftingEvents;

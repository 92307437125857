import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Link,
  HStack,
  Image,
  Stack,
  InputRightElement,
  Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { MdLock } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import UserRoutes from "../routes";
import authService from "../../services/auth/auth.service";

import { useEffect, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import google from "../../img/google.png";
import { useUserContext } from "../../components/common/UserContext";
import { log } from "util";
const Login = () => {
  const route = useNavigate();
  const toast = useToast();
  const [show, setShow] = useState(false);
  const [isGenerate, setisGenerate] = useState(false);
  const [parameter, setParameter] = useState<any>();
  const handleClick = () => setShow(!show);
  const { onDataChange } = useUserContext();
  const [selectCategory, setSelectedCategory] = useState<string>("user");

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.username) {
      errors.username = "Email is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };
  const switchToUser = () => {
    setSelectedCategory("user");
  };
  const formik = useFormik({
    initialValues: { username: "", password: "" },
    validate,
    onSubmit: async (values) => {
      try {
        const data = {
          login_type: "USER",
          email: values.username,
          password: values.password,
        };
        const dataVendor = {
          login_type: "VENDOR",
          email: values.username,
          password: values.password,
        };
        if (selectCategory == "user") {
          const login_user = await authService.login(data);
          if (login_user.token) {
            onDataChange({ ...login_user.user, token: login_user.token });
            sessionStorage.setItem("partigram_user_token", login_user.token);
            route(UserRoutes.portal.home);
            toast({
              title: "Login",
              description: `${login_user.message}`,
              status: "success",
            });
          }
        } else {
          const login_user = await authService.login(dataVendor);
          if (login_user.token) {
            sessionStorage.setItem("partigram_user_token", login_user.token);
            onDataChange({ ...login_user.user, token: login_user.token });
            route(UserRoutes.portal.home);
            toast({
              title: "Login",
              description: `${login_user.message}`,
              status: "success",
            });
          }
        }
      } catch (error: any) {
        toast({
          title: "Login",
          description: `${error.response.data.message}`,
          status: "error",
        });
      }
    },
  });

  return (
    <>
      <AuthLayout>
        <Flex
          justifyContent={["flex-end", "flex-end"]}
          borderBottom={[
            "2px solid #583A76",
            "2px solid #583A76",
            "none",
            "none",
          ]}
          ml={["0px", "0px", "30px", "30px"]}
          mt={["50px", "30px", "30px", "30px"]}
          mb={["50px", "30px", "50px", "50px"]}
          width={"100%"}
        >
          <Text
            ml={["20px", "20px", "0px", "0px"]}
            borderRadius={["8px 8px 0px 0px", "8px 8px 0px 0px", "4px", "4px"]}
            border={selectCategory == "user" ? "1px solid" : "1px solid"}
            borderColor={"#583A76"}
            color={selectCategory == "user" ? "white" : "#583A76"}
            bg={selectCategory == "user" ? "#583A76" : "white"}
            py={"6px"}
            px={"12px"}
            fontSize={"14px"}
            cursor={"pointer"}
            onClick={() => {
              switchToUser();
            }}
            mr={"2px"}
          >
            as a User
          </Text>
          <Text
            borderRadius={["8px 8px 0px 0px", "8px 8px 0px 0px", "4px", "4px"]}
            border={selectCategory == "vendor" ? "1px solid" : "1px solid"}
            borderColor={"#583A76"}
            color={selectCategory == "vendor" ? "white" : "#583A76"}
            bg={selectCategory == "vendor" ? "#583A76" : "white"}
            py={"6px"}
            px={"12px"}
            fontSize={"14px"}
            cursor={"pointer"}
            onClick={() => {
              setSelectedCategory("vendor");
            }}
            mr={"20px"}
          >
            as a Vendor
          </Text>
        </Flex>
        <Flex justifyContent={"center"} height="99vh">
          <Box>
            <Box mb="15px">
              <Box mb={"40px"}>
                <Text
                  mb={"10px"}
                  fontSize={["20px", "20px", "24px", "24px"]}
                  fontWeight="700"
                  color="#000000"
                  textAlign={"center"}
                >
                  Let’s go!
                </Text>
              </Box>

              <form onSubmit={formik.handleSubmit}>
                <FormControl
                  mb="20px"
                  isInvalid={
                    formik.errors.username && formik.touched.username
                      ? true
                      : false
                  }
                >
                  <FormLabel color="#757575" fontSize={15}>
                    Email
                  </FormLabel>

                  <Input
                    w={["300px", "300px", "400px", "400px"]}
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Enter email"
                    onChange={formik.handleChange}
                    fontSize={14}
                  />

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.username}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  mb="20px"
                  isInvalid={
                    formik.errors.password && formik.touched.password
                      ? true
                      : false
                  }
                >
                  <FormLabel color="brand.subtitle" fontSize={15}>
                    Password{" "}
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      color="brand.subtitle"
                      pointerEvents="none"
                      children={<MdLock />}
                    />
                    <Input
                      w={["300px", "300px", "400px", "400px"]}
                      type={show ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Enter  password"
                      onChange={formik.handleChange}
                      fontSize={14}
                    />
                    <InputRightElement width="6.5rem">
                      <Center
                        cursor={"pointer"}
                        h="1.75rem"
                        onClick={handleClick}
                      >
                        {show ? <BiHide /> : <BiShow />}
                      </Center>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage fontSize={12}>
                    {formik.errors.password}
                  </FormErrorMessage>
                </FormControl>
                <Flex justifyContent={["center", "center", "center", "center"]}>
                  <Center>
                    <Box
                      mb={"20px"}
                      fontWeight={"600"}
                      alignSelf="center"
                      color="#000000"
                      lineHeight={"35px"}
                      fontSize={"15px"}
                    >
                      <Link
                        onClick={() => {
                          route(UserRoutes.forgotPassword);
                        }}
                      >
                        Forgot Password?
                      </Link>
                    </Box>
                  </Center>
                </Flex>

                <Flex
                  justifyContent={[
                    "flex-start",
                    "flex-start",
                    "space-between",
                    "space-between",
                  ]}
                  flexDirection={[
                    "column-reverse",
                    "column-reverse",
                    "row",
                    "row",
                  ]}
                >
                  <Button
                    colorScheme="purple"
                    onClick={() => route(UserRoutes.Register)}
                    variant={"outline"}
                    height={"48px"}
                    fontWeight={"400"}
                    fontSize={"14px"}
                    size={"sm"}
                    mt={["10px", "10px", "0px", "0px"]}
                    width={["100%", "100%", "120px", "120px"]}
                  >
                    Register
                  </Button>
                  <Button
                    cursor="pointer"
                    color={"white"}
                    bg={"#583A76"}
                    height={"48px"}
                    type="submit"
                    fontWeight={"400"}
                    isLoading={formik.isSubmitting}
                    fontSize={"14px"}
                    size={"sm"}
                    width={["100%", "100%", "120px", "120px"]}
                  >
                    Login
                  </Button>
                </Flex>
                <Flex
                  mt={"30px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRadius={"10px"}
                  border={"0.996px solid #E9E9E9"}
                  py={"8px"}
                  width={"300px"}
                >
                  <Image src={google} />{" "}
                  <Text
                    ml={"10px"}
                    fontSize={["12px", "12px", "14px", "14px"]}
                    color={"#797979"}
                  >
                    Sign Up with Google
                  </Text>
                </Flex>
                <Flex mt={"20px"}>
                  <Text width={"300px"} fontSize={"12px"} color={"#797979"}>
                    By clicking button above, you agree to our Terms of Service{" "}
                    and Privacy Policy.
                  </Text>
                </Flex>
              </form>
            </Box>
          </Box>
        </Flex>
      </AuthLayout>
    </>
  );
};

export default Login;

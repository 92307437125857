const UserRoutes = {
  Register: "/register",
  CompleteProfile: "/complete-profile",
  Login: "/login",
  forgotPassword: "/forgot-password",
  emailSent: "/email-sent",
  emailVerified: "/email-verified",
  setPassword: "/set-password",
  portal: {
    home: "/partigram/home",
    categories: "/partigram/categories",
    vendors: "/partigram/vendors",
    myshelf: "/partigram/myshelf",
    viewEvents: "/partigram/my-events",
    createEvent: "/partigram/new-event",
    viewAnEvent: "/partigram/events/:event_id",
    viewAnEventLink: (event_id: string) => `/partigram/events/${event_id}`,
    editEvent: "/partigram/events/:event_id/edit-event",
    editEventLink: (event_id: string) =>
      `/partigram/events/${event_id}/edit-event`
  }
};

export default UserRoutes;
export const getFullDate = (datestring: string | undefined) => {
  if (datestring) {
    let date = new Date(
      datestring)
    let dateFormat = date.toLocaleDateString("en-US", { day: 'numeric' }) + " " + date.toLocaleDateString("en-US", { month: 'long' }) + "," + date.toLocaleDateString("en-US", { year: 'numeric' })
    return dateFormat
  }
  return 'Nil'
}


export const getTimeStamp = (datestring: string | undefined) => {
  if (datestring) {
    // Create a Date object with the timestamp

    const date = new Date(datestring);

    // Get the hours, minutes, and seconds
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Determine whether it's AM or PM
    const amOrPm = hours >= 12 ? "P.M." : "A.M.";

    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12; // Handle 0 as 12 for midnight

    // Format the time as a string
    const formattedTime = `${formattedHours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} ${amOrPm}`;


    return formattedTime
  }
  return 'Nil'

}
export const getFullDateNumeric = (datestring: string | undefined) => {
  if (datestring) {
    let date = new Date(datestring);
    let dateFormat =
      date.toLocaleDateString("en-US", { year: "numeric" }) +
      "-" +
      date.toLocaleDateString("en-US", { month: "numeric" }).padStart(2, "0") +
      "-" +
      date.toLocaleDateString("en-US", { day: "numeric" }).padStart(2, "0");

    return dateFormat;
  }
  return "";
};
export function getDateAndTime(inputDate: string | undefined) {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  if (inputDate) {
    const dateObj = new Date(inputDate);
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();
    let hours = dateObj.getHours();
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight

    const formattedDate = `${day},${month},${year}. ${hours}:${minutes}${ampm}`;
    return formattedDate;
  }
  return ''
}
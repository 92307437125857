import {
  Flex,
  Box,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  Button,
  Center,
  useToast,
  Portal,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Wrap,
  WrapItem,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel
} from "@chakra-ui/react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useEffect, useRef, useState } from "react";
import { PiCalendarPlus } from "react-icons/pi";
import { FiEye, FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { NumericFormat } from "react-number-format";
import { useParams, useNavigate } from "react-router-dom";
import { IUserEventData } from "../../../models/events/userEvents.model";
import eventsService from "../../../services/events/events.service";
import { getFullDate } from "../../../utils/getDate";
import UserRoutes from "../../routes";
import Loading from "../../../components/Loading";
import { AddEventModal } from "../../../components/user/AddEventActivity";
import event_activitiesService from "../../../services/events/event_activities.service";
import HireVendor from "../Vendors/HireVendor";
import axe from "../../../img/axe.png";
import { IEventActivitiesData } from "../../../models/events/event_activities.model";
import { EditEditEventActivityModal } from "../../../components/user/EditEventActivity";

const AnEvent = () => {
  const [loading, setLoading] = useState(true);
  const { event_id } = useParams();
  const router = useNavigate();
  const [myEvent, setmyEvent] = useState<IUserEventData>();
  const [myEventActivities, setmyEventActivities] =
  
    useState<IEventActivitiesData[]>();
  const toast = useToast(); 
  const cancelRef = useRef(null);
  const [reload, setReload] = useState(false);
  const [isEventActivity, setisEventActivity] = useState(false);
  const [eventActivity, setEventActivity] = useState<IEventActivitiesData>();
  const [isEditEventActivity, setisEditEventActivity] = useState(false);
  const [hireVendor, setHireVendor] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const onCloseAlert = () => setIsOpenAlert(false);
  const [categoryId, setCategoryId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [activityId, setactivityId] = useState<string>();
  const [activityName, setactivityName] = useState<string>();

  const handleHireVendor = (category: string, service: string) => {
    setCategoryId(category);
    setServiceId(service);
    setHireVendor(!hireVendor);
  };
  useEffect(() => {
    eventsService
      .getAnEvent(event_id)
      .then((data) => {
        setmyEvent(data.data);
        setLoading(false);
      })
      .catch((err) => err);
    event_activitiesService
      .getEventActivites(event_id)
      .then((data) => {
        setmyEventActivities(data.data);
        setLoading(false);
      })
      .catch((err) => err);
  }, [reload]);
  const openCreateEventActivity = () => {
    setisEventActivity(true);
    setisEditEventActivity(false);
  };
  const openEditEventActivity = (activity: IEventActivitiesData) => {
    setEventActivity(activity);
    setisEventActivity(false);
    setisEditEventActivity(true);
  };
  const reloadData = () => {
    setReload(!reload);
    setEventActivity(undefined);
    setisEventActivity(false);
    setisEditEventActivity(false);
    setactivityId("");
    setactivityName("");
  };
  const openDeleteEvent = (param: string, name: string) => {
    setactivityId(param);
    setactivityName(name);
    setIsOpenAlert(true);
  };
  const deletEvent = async () => {
    try {
      const delete_event: any =
        await event_activitiesService.deleteEventActivity(activityId);
      console.log(delete_event);
      if (delete_event) {
        toast({
          title: "Delete Acttivity",
          description: `${delete_event.message}`,
          status: "success"
        });
        setReload(!reload);
        setactivityId("");
        setactivityName("");
        onCloseAlert();
      } else {
        toast({
          title: "Delete Acttivity",
          description: `${delete_event.message}`,
          status: "error"
        });
      }
    } catch (error: any) {
      toast({
        title: "Delete Acttivity",
        description: `${error.response.data.message}`,
        status: "error"
      });
    }
  };
  return (
    <>
      <Flex
        justifyContent={["center", "center", "space-between", "space-between"]}
        px={["20px", "20px", "0px", "0px"]}
        alignItems={"center"}
        flexDirection={["column", "column", "row", "row"]}
        mb={["16px", "18px", "40px", "40px"]}
      >
        <Box
          color="#2B2B2B"
          fontWeight="600"
          fontSize={["20px", "20px", "30px", "30px"]}
          mb={["16px", "18px", "0px", "0px"]}
        >
          Events Details
        </Box>

        <HStack
          pl={"10px"}
          spacing="10px"
          bg={"#583A76"}
          cursor={"pointer"}
          fontWeight={"500"}
          px={["10px", "10px", "15px", "15px"]}
          py={["4px", "4px", "6px", "6px"]}
          color={"white"}
          borderRadius={"24px"}
          onClick={() => {
            openCreateEventActivity();
          }}
        >
          <Box as="span" pb="3px">
            <PiCalendarPlus fontSize={"18px"} color={"white"} />
          </Box>

          <Box
            mb="3px"
            mt="4px"
            pb="5px"
            pt={"2px"}
            fontSize={["12px", "12px", "12px", "12px"]}
            width="100%"
            color={"white"}
          >
            Add Event Activity
          </Box>
        </HStack>
      </Flex>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Box
            bg={"#F8FAFB"}
            display={["none", "none", "block", "block"]}
            mb={"20px"}
            borderRadius={"12px"}
          >
            <Flex px={"20px"} py={"30px"}>
              <Box>
                <Image
                  src={myEvent?.image}
                  borderRadius={"12px"}
                  width={"250px"}
                  height={"250px"}
                />
              </Box>
              <Box pl={"30px"} width={"calc(100% - 250px)"}>
                <Text
                  fontWeight="700"
                  mb={"20px"}
                  pb={"16px"}
                  borderBottom={"1px solid white"}
                  fontSize="24px"
                  color="#000000"
                >
                  {myEvent?.name}
                </Text>
                <Box>
                  <Flex mb={"20px"} justifyContent={"space-between"}>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Event Name
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myEvent?.name}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Event Category
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myEvent?.category.name}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Event Theme
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myEvent?.theme}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Status
                      </Text>
                      <Button
                        fontWeight={"400"}
                        letterSpacing={"1.4px"}
                        fontSize={"12px"}
                        colorScheme="green"
                        variant={"outline"}
                        fontStyle={"italic"}
                        borderRadius={"8px"}
                        height={"25px"}
                      >
                        Ongoing
                      </Button>
                    </Box>
                  </Flex>
                  <Flex justifyContent={"space-between"}>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Event Visibility
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myEvent?.visibility}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Date
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {getFullDate(myEvent?.date)}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Duration
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myEvent?.duration} hours
                      </Text>
                    </Box>
                    <Box visibility={"hidden"}>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Duration
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myEvent?.duration} hours
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>
            <Flex px={"20px"}>
              <Box width={"48%"}>
                <Text fontSize="15px" fontWeight="700" color="#000000">
                  Event Description
                </Text>
                <Text fontSize="14px" fontWeight="400" color="#000000">
                  {myEvent?.description}
                </Text>
              </Box>
              <Flex width={"50%"} mb={"20px"} justifyContent={"space-around"}>
                <Box>
                  <Text fontSize="14px" fontWeight="700" color="#000000">
                    Location
                  </Text>
                  <Text fontSize="14px" fontWeight="400" color="#000000">
                    {myEvent?.location}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="14px" fontWeight="700" color="#000000">
                    Venue Type
                  </Text>
                  <Text fontSize="14px" fontWeight="400" color="#000000">
                    {myEvent?.venue_type}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="14px" fontWeight="700" color="#000000">
                    No of guests
                  </Text>
                  <Text fontSize="14px" fontWeight="400" color="#000000">
                    {myEvent?.no_of_guest}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box
            mb={"20px"}
            borderRadius={"8px"}
            px={"10px"}
            bg={"#F8FAFB"}
            py={"10px"}
            mx={"20px"}
            display={["block", "block", "none", "none"]}
          >
            <Flex mb={"20px"} justifyContent={"space-between"}>
              <Image
                src={myEvent?.image}
                borderRadius={"8px"}
                width={"80px"}
                height={"80px"}
              />
              <Box>
                <Text
                  fontWeight="700"
                  pb={"16px"}
                  fontSize="18px"
                  color="#000000"
                  textAlign={"right"}
                >
                  {myEvent?.name}
                </Text>
                <Flex justifyContent={"flex-end"}>
                  <Text
                    mr={"10px"}
                    fontSize="14px"
                    fontWeight="700"
                    color="#000000"
                  >
                    Status
                  </Text>
                  <Button
                    fontWeight={"400"}
                    letterSpacing={"1.4px"}
                    fontSize={"12px"}
                    size={"sm"}
                    colorScheme="green"
                    variant={"outline"}
                    fontStyle={"italic"}
                    borderRadius={"8px"}
                    height={"25px"}
                  >
                    Ongoing
                  </Button>
                </Flex>
              </Box>
            </Flex>
            <Box mb={"20px"}>
              <Text fontSize="14px" fontWeight="700" color="#000000">
                Event Description
              </Text>
              <Text fontSize="14px" fontWeight="400" color="#000000">
                {myEvent?.description}
              </Text>
            </Box>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton bg={"white"}>
                    <Box
                      as="span"
                      fontSize="14px"
                      fontWeight="700"
                      color="#000000"
                      flex="1"
                      textAlign="left"
                    >
                      Other Details
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box>
                    <Flex mb={"15px"} justifyContent={"space-between"}>
                      <Box>
                        <Text fontSize="14px" fontWeight="700" color="#000000">
                          Event Name
                        </Text>
                        <Text
                          fontSize="14px"
                          fontWeight="400"
                          textAlign={"center"}
                          color="#000000"
                        >
                          {myEvent?.name}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize="14px" fontWeight="700" color="#000000">
                          Event Category
                        </Text>
                        <Text
                          fontSize="14px"
                          fontWeight="400"
                          textAlign={"center"}
                          color="#000000"
                        >
                          {myEvent?.category.name}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mb={"15px"} justifyContent={"space-between"}>
                      <Box>
                        <Text fontSize="14px" fontWeight="700" color="#000000">
                          Event Theme
                        </Text>
                        <Text
                          fontSize="14px"
                          textAlign={"center"}
                          fontWeight="400"
                          color="#000000"
                        >
                          {myEvent?.theme}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize="14px" fontWeight="700" color="#000000">
                          Event Visibility
                        </Text>
                        <Text
                          fontSize="14px"
                          fontWeight="400"
                          textAlign={"center"}
                          color="#000000"
                        >
                          {myEvent?.visibility}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex mb={"15px"} justifyContent={"space-between"}>
                      <Box>
                        <Text fontSize="14px" fontWeight="700" color="#000000">
                          Date
                        </Text>
                        <Text
                          fontSize="14px"
                          fontWeight="400"
                          textAlign={"center"}
                          color="#000000"
                        >
                          {getFullDate(myEvent?.date)}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize="14px" fontWeight="700" color="#000000">
                          Event Duration
                        </Text>
                        <Text
                          fontSize="14px"
                          fontWeight="400"
                          textAlign={"center"}
                          color="#000000"
                        >
                          {myEvent?.duration} hours
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Box px={"20px"}>
            <Text
              fontSize={["16px", "16px", "18px", "18px"]}
              mb={"20px"}
              fontWeight="700"
              color="#000000"
            >
              Event Activities
            </Text>

            <Wrap spacing={"40px"}>
              {myEventActivities && myEventActivities.length > 0 ? (
                myEventActivities.map((activity, index) => (
                  <WrapItem key={index}>
                    <Box
                      maxW={["344px", "344px", "450px", "450px"]}
                      borderRadius={"12px"}
                      p={["14px", "16px", "18px", "20px"]}
                      bg={"white"}
                      boxShadow={"base"}
                    >
                      <Flex
                        justifyContent={"space-between"}
                        pb={"5px"}
                        borderBottom={"1px solid lightgray"}
                      >
                        <HStack spacing={"15px"} alignItems={"center"}>
                          <Image src={axe} />
                          <Text
                            fontSize={["15px", "15px", "16px", "16px"]}
                            fontWeight="700"
                            color="#000000"
                          >
                            {activity.name}
                          </Text>
                        </HStack>
                        <HStack spacing={"15px"}>
                          <Text
                            fontSize="14px"
                            fontWeight="700"
                            color="#2D3748"
                          >
                            Status
                          </Text>
                          <Text
                            fontSize="14px"
                            p={"4px"}
                            border={"1px solid #1AB800"}
                            borderRadius={"8px"}
                            fontWeight="400"
                            color="#1AB800"
                          >
                            {activity.status}
                          </Text>
                        </HStack>
                      </Flex>
                      <Flex
                        mt={"30px"}
                        pl={"30px"}
                        justifyContent={"space-between"}
                      >
                        <Box mr={"30px"}>
                          <Text
                            fontSize={["11px", "12px", "14px", "14px"]}
                            fontWeight="700"
                            color="#000000"
                          >
                            Start Date
                          </Text>
                          <Text
                            fontSize={["11px", "12px", "13px", "13px"]}
                            fontWeight={["400", "400", "700", "700"]}
                            color="#000000"
                          >
                            {getFullDate(activity.start_date)}
                          </Text>
                        </Box>
                        <Box mr={"30px"}>
                          <Text
                            fontSize={["11px", "12px", "14px", "14px"]}
                            fontWeight="700"
                            color="#000000"
                          >
                            End Date
                          </Text>
                          <Text
                            fontSize={["11px", "12px", "13px", "13px"]}
                            fontWeight={["400", "400", "700", "700"]}
                            color="#000000"
                          >
                            {getFullDate(activity.end_date)}
                          </Text>
                        </Box>
                        <Box mr={"30px"}>
                          <Text
                            fontSize={["11px", "12px", "14px", "14px"]}
                            fontWeight="700"
                            color="#000000"
                          >
                            Budget
                          </Text>
                          <Text
                            fontWeight={["400", "400", "700", "700"]}
                            color="#000000"
                            fontSize={["11px", "12px", "13px", "13px"]}
                          >
                            <NumericFormat
                              value={activity.budget_amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={activity.currency.code}
                            />
                          </Text>
                        </Box>
                      </Flex>
                      <Flex
                        mt={"20px"}
                        alignItems={"center"}
                        pl={["0px", "0px", "30px", "30px"]}
                        justifyContent={"space-between"}
                      >
                        <HStack
                          cursor={"pointer"}
                          onClick={() => {
                            openEditEventActivity(activity);
                          }}
                        >
                          <Center>
                            <FiEdit fontSize={"22px"} />
                          </Center>
                          <Text
                            fontSize={["11px", "12px", "14px", "14px"]}
                            fontWeight="500"
                            color="#000000"
                          >
                            Edit
                          </Text>
                        </HStack>
                        <HStack
                          cursor={"pointer"}
                          onClick={() => {
                            openDeleteEvent(activity.id, activity.name);
                          }}
                        >
                          <Center>
                            <RiDeleteBinLine fontSize={"22px"} />
                          </Center>
                          <Text
                            fontSize={["11px", "12px", "14px", "14px"]}
                            fontWeight="500"
                            color="#000000"
                          >
                            Delete
                          </Text>
                        </HStack>

                        <Button
                          color={"white"}
                          onClick={(e) =>
                            handleHireVendor(categoryId, serviceId)
                          }
                          bg={"#1AB800"}
                          height={"44px"}
                          fontWeight={"400"}
                          fontSize={["11px", "12px", "13px", "13px"]}
                          size={"sm"}
                          fontStyle={"italic"}
                          px={["8px", "8px", "10px", "12px"]}
                        >
                          Source for vendor
                        </Button>
                      </Flex>
                    </Box>
                  </WrapItem>
                ))
              ) : (
                <Box>No data</Box>
              )}
            </Wrap>
          </Box>
          {isEventActivity && (
            <Portal>
              {" "}
              <AddEventModal
                isOpenEventActivity={isEventActivity}
                onClosed={setisEventActivity}
                isEventActivity={isEventActivity}
                eventId={event_id}
                reload={reloadData}
              />{" "}
            </Portal>
          )}
          {isEditEventActivity && (
            <Portal>
              {" "}
              <EditEditEventActivityModal
                isOpenEditEventActivity={isEditEventActivity}
                onClosed={setisEditEventActivity}
                isEditEventActivity={isEditEventActivity}
                activity={eventActivity}
                reload={reloadData}
              />{" "}
            </Portal>
          )}
          <Portal>
            <AlertDialog
              isOpen={isOpenAlert}
              leastDestructiveRef={cancelRef}
              onClose={onCloseAlert}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="md" fontWeight="semibold">
                    Delete {activityName}
                  </AlertDialogHeader>

                  <AlertDialogBody fontSize={"14px"}>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button size={"sm"} ref={cancelRef} onClick={onCloseAlert}>
                      Cancel
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme="red"
                      onClick={deletEvent}
                      ml={3}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Portal>
          {hireVendor && (
            <HireVendor categoryId={categoryId} serviceId={serviceId} />
          )}
        </Box>
      )}
    </>
  );
};

export default AnEvent;

import { IEventActivitiesRes } from "../../models/events/event_activities.model";
import { IUserAnEventRes, IUserEventRes } from "../../models/events/userEvents.model";
import deleteService from "../crud-requests/deleteService";
import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import postServiceMultipart from "../crud-requests/postServiceMultipart";
import putService from "../crud-requests/putService";


const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL;

class EventActivitiesService {
    async getEventActivites(id: string | undefined): Promise<IEventActivitiesRes> {
        const response = await getService.get(
            `${CONFIG_API_URL}event-activities/${id}`,
        );
        return response.data;
    }
    async getAnEventActivity(id: string | undefined): Promise<IEventActivitiesRes> {
        const response = await getService.get(
            `${CONFIG_API_URL}event-activities/${id}`,
        );
        return response.data;
    }
    async createEventActivity(data: any, id: string | undefined) {
        const response = await postService.post(
            `${CONFIG_API_URL}event-activities/${id}`,
            data
        );
        return response.data;
    }
    async updateEventActivity(data: any, id: string | undefined) {
        const response = await putService.put(
            `${CONFIG_API_URL}event-activities/${id}`,
            data
        );
        return response.data;
    }
    async deleteEventActivity(id: string | undefined) {
        const response = await deleteService.delete(
            `${CONFIG_API_URL}event-activities/${id}`,
        );
        return response.data;
    }

}

export default new EventActivitiesService();
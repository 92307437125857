import { IUserAnEventRes, IUserEventRes } from "../../models/events/userEvents.model";
import deleteService from "../crud-requests/deleteService";
import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import postServiceMultipart from "../crud-requests/postServiceMultipart";
import putService from "../crud-requests/putService";


const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL;

class EventsService {
    async getEvents() : Promise<IUserEventRes> {
        const response = await getService.get(
            `${CONFIG_API_URL}events`,

        );
        return response.data;
    }
    async getAnEvent(id: string | undefined) : Promise<IUserAnEventRes> {
        const response = await getService.get(
            `${CONFIG_API_URL}events/${id}`,

        );
        return response.data;
    }
    async createEvent(data: any) {
        const response = await postServiceMultipart.post(
            `${CONFIG_API_URL}events`,
            data
        );
        return response.data;
    }
    async updateEvent(data: any, id: string | undefined) {
        const response = await postServiceMultipart.post(
            `${CONFIG_API_URL}events/${id}`,
            data
        );
        return response.data;
    }
    async deleteEvent(id: string | undefined) {
        const response = await deleteService.delete(
            `${CONFIG_API_URL}events/${id}`,
        );
        return response.data;
    }

}

export default new EventsService();
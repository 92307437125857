import { Flex, Image, HStack, Box, Text } from "@chakra-ui/react";
import logo from "../../img/partilogo.png";
import stripe from "../../img/partistrip.png";
import hstripe from "../../img/horizontalStrip.png";
import { useLocation } from "react-router-dom";
const AuthLayout = (props: any) => {
  const location = useLocation();
  return (
    <Flex height="100vh" flexDirection={["column", "column", "row", "row"]}>
      <Box
        width={["100%", "100%", "30%", "48%"]}
        height={
          location.pathname == "/login"
            ? "100vh"
            : location.pathname == "/register"
            ? "150vh"
            : "100vh"
        }
        bg={"white"}
        px={["0px", "0px", "40px", "40px"]}
      >
        <Box
          py={"20px"}
          pl={'20px'}

          boxShadow={[
            "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            "none",
            "none",
          ]}
        >
          <Image src={logo} height={["60px"]} />
        </Box>
        {location.pathname == "/login" ? (
          <Box px={"20px"} mt={["50px", "50px", "60px", "80px"]}>
            <Text
              mb={["17px", "25px", "95px", "95px"]}
              fontWeight={"700"}
              fontSize={"28px"}
              color={"#000000"}
            >
              Login
            </Text>
            <Text
              fontWeight={"400"}
              fontSize={["15px", "15px", "16px", "16px"]}
              color={"#000000"}
              mb={["50px", "50px", "0px", "0px"]}
              maxW={'560px'}
            >
              Already a member of PartiGram?  <br />
              Log in to access your personalized dashboard, track your events,{" "}
              
              and explore a world of event planning convenience.
            </Text>
          </Box>
        ) : location.pathname === "/register" ? (
          <Box px={"20px"} mt={["50px", "50px", "60px", "80px"]}>
            <Text
              mb={["17px", "25px", "95px", "95px"]}
              fontWeight={"700"}
              fontSize={"28px"}
              color={"#000000"}
            >
              Register
            </Text>
            <Text
              display={["none", "none", "block", "block"]}
              fontWeight={"400"}
              fontSize={["15px", "15px", "16px", "16px"]}
              color={"#000000"}
              mb={["50px", "50px", "0px", "0px"]}
            >
              Sign up for a free account with PartiGram and unlock a world of{" "}
              <br></br>
              possibilities. Plan your events with ease and make every <br></br>
              moment memorable. Your journey starts here.{" "}
            </Text>
            <Text
              display={["block", "block", "none", "none"]}
              fontWeight={"400"}
              fontSize={["15px", "15px", "16px", "16px"]}
              color={"#000000"}
              mb={["50px", "50px", "0px", "0px"]}
            >
              Sign up for a free account with PartiGram and unlock a world of
              possibilities{" "}
            </Text>
          </Box>
        ) : location.pathname == "/forgot-password" ? (
          <Box px={"20px"} mt={["50px", "50px", "60px", "80px"]}>
            <Text
              mb={["17px", "25px", "95px", "95px"]}
              fontWeight={"700"}
              fontSize={"28px"}
              color={"#000000"}
            >
              Forgot Password
            </Text>

            <Text
              display={["block", "block", "none", "none"]}
              fontWeight={"400"}
              fontSize={["15px", "15px", "16px", "16px"]}
              color={"#000000"}
              mb={["50px", "50px", "0px", "0px"]}
            >
              Enter email to reset password
            </Text>
          </Box>
        ) : location.pathname == '/set-password' ? (
          <Box px={"20px"} mt={["50px", "50px", "60px", "80px"]}>
            <Text
              mb={["17px", "25px", "95px", "95px"]}
              fontWeight={"700"}
              fontSize={"28px"}
              color={"#000000"}
            >
              Reset Password
            </Text>
            <Text
              display={["none", "none", "block", "block"]}
              fontWeight={"400"}
              fontSize={["15px", "15px", "16px", "16px"]}
              color={"#000000"}
              mb={["50px", "50px", "0px", "0px"]}
            >
              Reset your password by entering your otp and new password
            </Text>
            <Text
              display={["block", "block", "none", "none"]}
              fontWeight={"400"}
              fontSize={["15px", "15px", "16px", "16px"]}
              color={"#000000"}
              mb={["50px", "50px", "0px", "0px"]}
            >
              Reset your password by entering your otp and new password
            </Text>
          </Box>
        )
      :  <Box px={"20px"} mt={["50px", "50px", "60px", "80px"]}>
      <Text
        mb={["17px", "25px", "95px", "95px"]}
        fontWeight={"700"}
        fontSize={"28px"}
        color={"#000000"}
      >
        Register
      </Text>
      <Text
        display={["none", "none", "block", "block"]}
        fontWeight={"400"}
        fontSize={["15px", "15px", "16px", "16px"]}
        color={"#000000"}
        mb={["50px", "50px", "0px", "0px"]}
      >
        Sign up for a free account with PartiGram and unlock a world of{" "}
        <br></br>
        possibilities. Plan your events with ease and make every <br></br>
        moment memorable. Your journey starts here.{" "}
      </Text>
      <Text
        display={["block", "block", "none", "none"]}
        fontWeight={"400"}
        fontSize={["15px", "15px", "16px", "16px"]}
        color={"#000000"}
        mb={["50px", "50px", "0px", "0px"]}
      >
        Sign up for a free account with PartiGram and unlock a world of
        possibilities{" "}
      </Text>
    </Box>}
      </Box>
      <Box
        bgImage={[hstripe, hstripe, stripe, stripe]}
        height={
          location.pathname == "/login"
            ? ["10px", "10px", "100vh", "100vh"]
            : location.pathname == "/register"
            ? "150vh"
            : ["10px", "10px", "100vh", "100vh"]
        }
        width={["100%", "100%", "10px", "10px"]}
      >
        <Image display={["block", "block", "none", "none"]} src={hstripe} />
      </Box>
      <Box width={["100%", "100%", "50%", "50%"]}>{props.children}</Box>
    </Flex>
  );
};

export default AuthLayout;

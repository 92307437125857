import {
  Flex,
  Box,
  Image,
  Text,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Select,
  Textarea,
  Checkbox,
  Button,
  HStack,
} from "@chakra-ui/react";
import bgMObile from "../../../img/bgNewEventMobile.png";
import eventbg from "../../../img/bgNewEvent.png";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import authService from "../../../services/auth/auth.service";
import UserRoutes from "../../routes";
import { useEffect, useRef, useState } from "react";
import { BsCheck2 } from "react-icons/bs";
import eventsService from "../../../services/events/events.service";
import { ICategoriesData } from "../../../models/categories/categories.model";
import commonService from "../../../services/common/common.service";
import { IUserEventData } from "../../../models/events/userEvents.model";
const EditEvent = () => {
  const route = useNavigate();
  const toast = useToast();
  const { event_id } = useParams();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [filename, setFilename] = useState("");
  const [filetype, setFiletype] = useState<string | undefined>("");
  const [venueType, setvenueType] = useState(false);
  const [outdoor, setOutdoor] = useState(false);
  const [imageFile, setimageFile] = useState<any>();
  const [imageSize, setimageSize] = useState<string>();
  const [myEvent, setmyEvent] = useState<IUserEventData>();
  const [loading, setLoading] = useState(true);
  const [categoriesList, setCategoriesList] = useState<ICategoriesData[]>();

  useEffect(() => {
    commonService
      .getCatgories()
      .then((data) => {
        setCategoriesList(data.data);
      })
      .catch((err) => err);
    eventsService
      .getAnEvent(event_id)
      .then((data) => {
        const theEvent = data.data;
        setmyEvent(theEvent);
        formik.setValues({
          name: theEvent.name,
          category: theEvent.category.id,
          theme: theEvent.theme,
          visibility: theEvent.visibility,
          description: theEvent.description,
          date: theEvent.date,
          time: theEvent.time,
          duration: theEvent.duration,
          location: theEvent.location,
          guestNo: theEvent.no_of_guest,
        });
        theEvent.venue_type == "indoor" ? setvenueType(true) : setOutdoor(true);
        setLoading(false);
      })
      .catch((err) => err);
  }, []);
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "name is required";
    }
    if (!values.theme) {
      errors.theme = "theme is required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
      theme: "",
      visibility: "",
      description: "",
      date: "",
      time: "",
      duration: "",
      location: "",
      guestNo: "",
    },
    validate,
    onSubmit: async (values) => {
      try {
        const newEvent = new FormData();
        newEvent.append("name", values.name);
        newEvent.append("theme", values.theme);
        newEvent.append("description", values.description);
        newEvent.append("category_id", values.category);
        newEvent.append("date", values.date);
        newEvent.append("time", values.time);
        newEvent.append("duration", values.duration);
        newEvent.append("location", values.location);
        newEvent.append("no_of_guest", values.guestNo);
        newEvent.append("venue_type", venueType ? "indoor" : "outdoor");
        newEvent.append("visibility", values.visibility);
        newEvent.append("image", imageFile);
        const create_event = await eventsService.updateEvent(
          newEvent,
          myEvent?.id
        );
        if (create_event) {
          toast({
            title: "Events",
            description: `${create_event.message}`,
            status: "success",
          });
          route(UserRoutes.portal.viewEvents);
        }
      } catch (error: any) {
        toast({
          title: "Events",
          description: `${error.response.data.message}`,
          status: "error",
        });
      }
    },
  });
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (value && e.target.files) {
      let fileName = e.target.files?.item(0)?.name as string;
      setFilename(fileName);
      let fileType = e.target.files?.item(0)?.type;
      setFiletype(fileType);
      let file = e.target.files[0];
      setimageFile(file);
      const fileSizeInBytes = file.size; // Get the size of the file in bytes
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convert to MB
      setimageSize(`${fileSizeInMB.toFixed(2)} MB`);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Flex pb={"60px"} flexDirection={["column", "column", "row", "row"]}>
          <Box
            maxW={"760px"}
            width={"100%"}
            bg={["#FFFFFF", "#FFFFFF", "#F8FAFB", "#F8FAFB"]}
          >
            <Box display={["block", "block", "none", "none"]}>
              <Image src={myEvent?.image} width={"100%"} />
            </Box>
            <Box display={["none", "none", "block", "block"]}>
              <Image src={myEvent?.image} />
            </Box>

            <Box mt={"28px"} px={["20px", "20px", "30px", "30px"]}>
              <FormControl
                mb="15px"
                isInvalid={
                  formik.errors.name && formik.touched.name ? true : false
                }
              >
                <FormLabel color="#000000" fontSize={"12px"}>
                  Event Name
                </FormLabel>

                <Input
                  w={"100%"}
                  type="text"
                  id="name"
                  height={["40px", "40px", "60px", "60px"]}
                  name="name"
                  value={formik.values.name}
                  placeholder="Enter event name"
                  onChange={formik.handleChange}
                  fontSize={"14px"}
                />

                <FormErrorMessage fontSize={12}>
                  {formik.errors.name}
                </FormErrorMessage>
              </FormControl>
              <Stack
                mb={"15px"}
                direction={["column", "row"]}
                spacing={["15px", "15px", "15px", "15px"]}
              >
                <FormControl
                  mb="0px"
                  isInvalid={
                    formik.errors.name && formik.touched.name ? true : false
                  }
                >
                  <FormLabel color="#000000" fontSize={"12px"}>
                    Event Category
                  </FormLabel>

                  <Select
                    variant="outline"
                    size="sm"
                    borderRadius={"10px"}
                    placeholder="select category"
                    value={formik.values.category}
                    name="category"
                    bg={"#E9E9E9"}
                    onChange={formik.handleChange}
                    fontSize={"14px"}
                  >
                    {categoriesList &&
                      categoriesList.map((each, index) => (
                        <option key={index} value={each.id}>
                          {" "}
                          {`${each.name}`}
                        </option>
                      ))}
                  </Select>
                  <FormErrorMessage fontSize={12}>
                    {formik.errors.name}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mb="0px">
                  <FormLabel color="#000000" fontSize={"12px"}>
                    Event Theme
                  </FormLabel>

                  <Input
                    w={"100%"}
                    type="text"
                    id="theme"
                    size="sm"
                    name="theme"
                    value={formik.values.theme}
                    placeholder="Enter event theme"
                    onChange={formik.handleChange}
                    fontSize={"14px"}
                  />

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.theme}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mb="0px">
                  <FormLabel color="#000000" fontSize={"12px"}>
                    Event Visibility
                  </FormLabel>

                  <Select
                    variant="outline"
                    size="sm"
                    bg={"#E9E9E9"}
                    borderRadius={"10px"}
                    placeholder="select visibility"
                    value={formik.values.visibility}
                    name="visibility"
                    onChange={formik.handleChange}
                    fontSize={"14px"}
                  >
                    <option value={"private"}> Private</option>
                    <option value={"public"}> Public</option>
                  </Select>
                  <FormErrorMessage fontSize={12}>
                    {formik.errors.visibility}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
              <FormControl
                mb="15px"
                isInvalid={
                  formik.errors.name && formik.touched.name ? true : false
                }
              >
                <FormLabel
                  fontWeight="normal"
                  color="#000000"
                  fontSize={"12px"}
                >
                  Description
                </FormLabel>
                <Textarea
                  p={4}
                  fontSize={"14px"}
                  size="sm"
                  minH="90px"
                  placeholder=" Description"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />

                <FormErrorMessage fontSize={12}>
                  {formik.errors.name}
                </FormErrorMessage>
              </FormControl>
              <Stack
                mb={"15px"}
                direction={["column", "row"]}
                spacing={["15px", "15px", "15px", "15px"]}
              >
                <FormControl
                  mb="0px"
                  isInvalid={
                    formik.errors.date && formik.touched.date ? true : false
                  }
                >
                  <FormLabel color="#000000" fontSize={"12px"}>
                    Date
                  </FormLabel>

                  <Input
                    w={"100%"}
                    type="date"
                    id="date"
                    size="sm"
                    name="date"
                    placeholder=""
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    fontSize={"14px"}
                  />

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.date}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mb="0px">
                  <FormLabel color="#000000" fontSize={"12px"}>
                    Time
                  </FormLabel>

                  <Input
                    w={"100%"}
                    type="time"
                    id="time"
                    size="sm"
                    name="time"
                    placeholder="02:00pm"
                    value={formik.values.time}
                    onChange={formik.handleChange}
                    fontSize={"14px"}
                  />

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.time}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mb="0px">
                  <FormLabel color="#000000" fontSize={"12px"}>
                    Duration
                  </FormLabel>

                  <Input
                    w={"100%"}
                    type="text"
                    id="duration"
                    size="sm"
                    name="duration"
                    value={formik.values.duration}
                    placeholder="02:00pm"
                    onChange={formik.handleChange}
                    fontSize={"14px"}
                  />

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.duration}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
              <Stack
                mb={"15px"}
                direction={["column", "row"]}
                spacing={["15px", "15px", "15px", "15px"]}
              >
                <FormControl
                  mb="0px"
                  isInvalid={
                    formik.errors.location && formik.touched.location
                      ? true
                      : false
                  }
                >
                  <FormLabel color="#000000" fontSize={"12px"}>
                    Location
                  </FormLabel>

                  <Input
                    height={["40px", "40px", "40px", "40px"]}
                    type="text"
                    id="location"
                    w={["100%", "100%", "250px", "250px"]}
                    name="location"
                    value={formik.values.location}
                    placeholder=""
                    onChange={formik.handleChange}
                    fontSize={"14px"}
                  />

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.location}
                  </FormErrorMessage>
                </FormControl>
                <Stack
                  fontSize={"12px"}
                  spacing={"15px"}
                  color={"#00000"}
                  direction="row"
                  alignItems={"center"}
                >
                  <Text width={"100px"} fontWeight={"600"}>
                    Venue Type
                  </Text>
                  <Checkbox
                    size={"sm"}
                    fontSize={"16px"}
                    isChecked={venueType}
                    color={"#000000"}
                    onChange={(e) => setvenueType(e.target.checked)}
                    colorScheme="green"
                  >
                    Indoor
                  </Checkbox>
                  <Checkbox
                    size={"sm"}
                    fontSize={"16px"}
                    isChecked={outdoor}
                    color={"#575757"}
                    onChange={(e) => setOutdoor(e.target.checked)}
                    colorScheme="green"
                  >
                    Outdoor
                  </Checkbox>
                </Stack>
              </Stack>
              <FormControl
                mb="15px"
                isInvalid={
                  formik.errors.name && formik.touched.name ? true : false
                }
              >
                <FormLabel color="#000000" fontSize={"12px"}>
                  No of Guests
                </FormLabel>

                <Input
                  w={["100%", "100%", "400px", "430px"]}
                  type="number"
                  id="guestNo"
                  height={["40px", "40px", "40px", "40px"]}
                  name="guestNo"
                  value={formik.values.guestNo}
                  placeholder="Enter expected number of guests"
                  onChange={formik.handleChange}
                  fontSize={"14px"}
                />

                <FormErrorMessage fontSize={12}>
                  {formik.errors.guestNo}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Box>
          <Box
            ml={["0px", "0px", "40px", "40px"]}
            minW={["300px", "300px", "400px", "400px"]}
            px={"20px"}
          >
            <Text
              fontWeight="700"
              mb={"20px"}
              pb={"16px"}
              borderBottom={"1px solid white"}
              fontSize="16px"
              color="#000000"
            >
              Upload Documents
            </Text>
            <Box px={"20px"} borderRadius={"12px"} py={"20px"} bg={"#F8FAFB"}>
              <Box
                py={"10px"}
                mb={"15px"}
                borderBottom={"1.5px lightgrey solid"}
              >
                <input
                  accept="image/*"
                  type="file"
                  name="cover_image"
                  ref={inputRef}
                  onChange={(e) => handleFileChange(e)}
                  style={{ display: "none" }}
                ></input>
                <Button
                  size={"sm"}
                  bg={"#E9E9E9"}
                  fontSize={"10px"}
                  px={"10px"}
                  onClick={() => inputRef?.current?.click()}
                  borderRadius={"8px"}
                  colorScheme="gray"
                >
                  Select file
                </Button>{" "}
              </Box>
              {filename && (
                <HStack spacing={"15px"}>
                  <BsCheck2 color="#319BFF" />
                  <Text>{filename}</Text>

                  <Text
                    bg={"#6F52ED"}
                    px={"10px"}
                    py={"6px"}
                    fontSize={"10px"}
                    borderRadius={"18px"}
                  >
                    {imageSize}
                  </Text>
                </HStack>
              )}
            </Box>
            <Button
              position={"absolute"}
              bottom={["20px", "20px", "60px", "60px"]}
              right={["0px", "0px", "20px", "20px"]}
              cursor="pointer"
              color={"white"}
              bg={"#583A76"}
              height={["48px", "48px", "60px", "60px"]}
              type="submit"
              fontWeight={"400"}
              isLoading={formik.isSubmitting}
              fontSize={"16px"}
              size={"sm"}
              width={["100%", "100%", "150px", "150px"]}
            >
              Edit Event
            </Button>
          </Box>
        </Flex>
      </form>
    </>
  );
};

export default EditEvent;

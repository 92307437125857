import {
  Avatar,
  Box,
  Divider,
  Flex,
  HStack,
  Text,
  Image,
  Link,
  Container,
  Button,
  VStack,
  InputGroup,
  InputRightAddon,
  Input,
  Center,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaTwitter, FaInstagram, FaFacebookF, FaYoutube } from "react-icons/fa";

import {
  MdClose,
  MdEmail,
  MdMenu,
  MdOndemandVideo,
  MdPhone,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import naptinlogo from "../../img/partilogo.png";
import { BsChevronDown } from "react-icons/bs";
import { BiMenuAltLeft } from "react-icons/bi";

const Header = () => {
  const navigate = useNavigate();
  const [display, changeDisplay] = useState("none");
  return (
    <>
      <Flex
        width="100%"
        justifyContent={["space-between", "space-between", "none", "none"]}
        bg={["white", "white", "inherit", "inherit"]}
        position={["fixed", "fixed", "absolute", "absolute"]}
        zIndex={"100"}
        pb="10px"
        className="animate__animated animate__fadeInDown"
        marginTop="0px"
        px={["20px", "20px", "30px", "60px"]}
      >
        <Box width="100%" display={["none", "none", "block", "block"]}>
          <Box>
            <Flex
              height="60px"
              bg={["white", "white", "inherit", "inherit"]}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex alignItems="center">
                <Link as="span">
                  <Image src={naptinlogo} height="60px" />
                </Link>
              </Flex>
              <HStack
                spacing={["none", "none", "40px", "70px"]}
                color="#616161"
                fontSize={["none", "none", "12px", "14px"]}
              >
                <Link lineHeight={10} fontWeight="600">
                  Home
                </Link>

                <Link lineHeight={10} fontWeight="600">
                  About
                </Link>
              </HStack>
            </Flex>
          </Box>
        </Box>
        <Flex
          alignItems="center"
          mt="5px"
          display={["flex", "flex", "none", "none"]}
        >
          <Link as="span">
            <Image src={naptinlogo} height="60px" />
          </Link>
        </Flex>
        <Flex display={["flex", "flex", "none", "none"]} mt="5px">
          <Center>
            <BiMenuAltLeft
              fontSize={"35px"}
              cursor={"pointer"}
              onClick={() => {
                changeDisplay("flex");
              }}
            />
          </Center>
        </Flex>
        <Flex
          zIndex={"100"}
          w="100vw"
          h="100vh"
          bg="#FFFAFA"
          pos="fixed"
          top="0"
          left="0"
          flexDir="column"
          display={display}
          overflowY="auto"
        >
          <Flex mt="5px" justifyContent="flex-end">
            <IconButton
              bg="#FFFAFA"
              mr={6}
              aria-label="Open Menu"
              size="lg"
              icon={<MdClose />}
              onClick={() => {
                changeDisplay("none");
              }}
            />
          </Flex>
          <Flex flexDir="column" align="center">
            <Box as="span">
              <Link
                color="#EC3337"
                lineHeight={"16px"}
                fontWeight="600"
                fontSize="12px"
              >
                Login
              </Link>
            </Box>
            <Link lineHeight={10} fontWeight="600">
              About
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Header;

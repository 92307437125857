import {
  Flex,
  Box,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  Button,
  Center,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Portal,
} from "@chakra-ui/react";
import { PiCalendarPlus } from "react-icons/pi";
import eventImg from "../../../img/eventList.png";
import { FiEdit, FiEye } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import UserRoutes from "../../routes";
import eventsService from "../../../services/events/events.service";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Loading from "../../../components/Loading";
import { IUserEventData } from "../../../models/events/userEvents.model";
import { getFullDate } from "../../../utils/getDate";

const ViewEvents = () => {
  const router = useNavigate();
  const [eventList, seteventList] = useState<IUserEventData[]>();
  const toast = useToast();
  const cancelRef = useRef(null);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    eventsService
      .getEvents()
      .then((data) => {
        seteventList(data.data);
        setLoading(false); // Set loading to false after 3 seconds
      })
      .catch((err) => err);
  }, [reload]);
  const [loading, setLoading] = useState(true);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const onCloseAlert = () => setIsOpenAlert(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [eventId, seteventId] = useState<string>();
  const [eventName, seteventName] = useState<string>();
  const filteredeventList =
    eventList &&
    eventList.filter((event) =>
      event.name.toString().toLowerCase().includes(searchQuery.toString())
    );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    filteredeventList &&
    filteredeventList.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages =
    filteredeventList && Math.ceil(filteredeventList.length / itemsPerPage);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    if (totalPages && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };
  const openDeleteEvent = (param: string, name: string) => {
    seteventId(param);
    seteventName(name);
    setIsOpenAlert(true);
  };
  const viewEvent = (eventId: string) => {
    router(UserRoutes.portal.viewAnEventLink(eventId));
  };
  const editEvent = (eventId: string) => {
    router(UserRoutes.portal.editEventLink(eventId));
  };

  const deletEvent = async () => {
    try {
      const delete_event: any = await eventsService.deleteEvent(eventId);
      console.log(delete_event);
      if (delete_event) {
        toast({
          title: "Delete Event",
          description: `${delete_event.message}`,
          status: "success",
        });
        setReload(!reload);
        seteventId("");
        seteventName("");
        onCloseAlert();
      } else {
        toast({
          title: "Delete Event",
          description: `${delete_event.message}`,
          status: "error",
        });
      }
    } catch (error: any) {
      toast({
        title: "Delete Event",
        description: `${error.response.data.message}`,
        status: "error",
      });
    }
  };
  return (
    <>
      <Flex
        px={["20px", "20px", "0px", "0px"]}
        justifyContent={"space-between"}
        flexDirection={["column", "column", "row", "row"]}
        mb={["16px", "18px", "40px", "40px"]}
      >

        <Flex
          alignItems={"center"}
          mb={["14px", "14px", "0px", "0px"]}
          justifyContent={"space-between"}
          width={['100%','100%','50%','50%']}
        >
          <Box
            color="#2B2B2B"
            fontWeight="600"
            fontSize={["20px", "20px", "30px", "30px"]}
          >
            Events
          </Box>
          <Menu>
            <MenuButton
              bg="white"
              py={["8px", "8px", "12px", "12px"]}
              px={"36px"}
              borderRadius={"24px"}
              border={"1.5px solid #D1D1D1"}
              color="#616161"
              fontSize={"13px"}
            >
              Status : All
            </MenuButton>
            <MenuList fontSize={12}>
              <MenuItem fontSize={"13px"} color={"#202020"}>
                Status
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <HStack
          pl={"10px"}
          spacing="10px"
          mb="15px"
          bg={"#583A76"}
          cursor={"pointer"}
          fontWeight={"500"}
          px={"20px"}
          py={"8px"}
          color={"white"}
          borderRadius={"24px"}
          width={"fit-content"}
          onClick={() => {
            router(UserRoutes.portal.createEvent);
          }}
        >
          <Box as="span" pb="3px">
            <PiCalendarPlus fontSize={"30px"} color={"white"} />
          </Box>

          <Box
            mb="3px"
            mt="4px"
            pb="5px"
            pt={"2px"}
            fontSize={["13px", "13px", "14px", "14px"]}
            width="100%"
            color={"white"}
          >
            Create New Event
          </Box>
        </HStack>
      </Flex>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Box display={["none", "none", "block", "block"]}>
            {currentItems && currentItems.length > 0 ? (
              currentItems.map((myevent, index) => (
                <Flex
                  mb={"20px"}
                  borderRadius={"12px"}
                  px={"20px"}
                  bg={"#F8FAFB"}
                  py={"30px"}
                  key={index}
                >
                  <Box>
                    <Image
                      src={myevent.image}
                      borderRadius={"12px"}
                      width={"250px"}
                      height={"250px"}
                    />
                  </Box>
                  <Box pl={"30px"} width={"calc(100% - 250px)"}>
                    <Text
                      fontWeight="700"
                      mb={"20px"}
                      pb={"16px"}
                      borderBottom={"1px solid white"}
                      fontSize="24px"
                      color="#000000"
                    >
                      {myevent.name}
                    </Text>
                    <Box>
                      <Flex mb={"20px"} justifyContent={"space-between"}>
                        <Box>
                          <Text
                            fontSize="14px"
                            fontWeight="700"
                            color="#000000"
                          >
                            Event Name
                          </Text>
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="#000000"
                          >
                            {myevent.name}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            fontSize="14px"
                            fontWeight="700"
                            color="#000000"
                          >
                            Event Category
                          </Text>
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="#000000"
                          >
                            {myevent.category.name}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            fontSize="14px"
                            fontWeight="700"
                            color="#000000"
                          >
                            Event Theme
                          </Text>
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="#000000"
                          >
                            {myevent.theme}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            fontSize="14px"
                            fontWeight="700"
                            color="#000000"
                          >
                            Status
                          </Text>
                          <Button
                            fontWeight={"400"}
                            letterSpacing={"1.4px"}
                            fontSize={"12px"}
                            colorScheme="green"
                            variant={"outline"}
                            fontStyle={"italic"}
                            borderRadius={"8px"}
                            height={"25px"}
                          >
                            Ongoing
                          </Button>
                        </Box>
                      </Flex>
                      <Flex justifyContent={"space-between"}>
                        <Box>
                          <Text
                            fontSize="14px"
                            fontWeight="700"
                            color="#000000"
                          >
                            Event Visibility
                          </Text>
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="#000000"
                          >
                            {myevent.visibility}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            fontSize="14px"
                            fontWeight="700"
                            color="#000000"
                          >
                            Date
                          </Text>
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="#000000"
                          >
                            {getFullDate(myevent.date)}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            fontSize="14px"
                            fontWeight="700"
                            color="#000000"
                          >
                            Duration
                          </Text>
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="#000000"
                          >
                            {myevent.duration} hours
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            fontSize="14px"
                            fontWeight="700"
                            color="#000000"
                          >
                            Actions
                          </Text>
                          <HStack spacing={"15px"}>
                            <Center
                              cursor={"pointer"}
                              onClick={() => {
                                viewEvent(myevent.id);
                              }}
                            >
                              <FiEye fontSize={"22px"} />
                            </Center>
                            <Center
                              cursor={"pointer"}
                              onClick={() => {
                                editEvent(myevent.id);
                              }}
                            >
                              <FiEdit fontSize={"22px"} />
                            </Center>
                            <Center
                              cursor={"pointer"}
                              onClick={() => {
                                openDeleteEvent(myevent.id, myevent.name);
                              }}
                            >
                              <MdDelete fontSize={"22px"} />
                            </Center>
                          </HStack>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                </Flex>
              ))
            ) : (
              <Box>No data</Box>
            )}
          </Box>
          <Box px={"20px"} display={["block", "block", "none", "none"]}>
            {currentItems && currentItems.length > 0 ? (
              currentItems.map((myevent, index) => (
                <Box
                  mb={"20px"}
                  borderRadius={"8px"}
                  px={"10px"}
                  bg={"#F8FAFB"}
                  py={"10px"}
                  key={index}
                  boxShadow={"base"}
                >
                  <Flex mb={"20px"} justifyContent={"space-between"}>
                    <Image
                      src={myevent.image}
                      borderRadius={"8px"}
                      width={"80px"}
                      height={"80px"}
                    />
                    <Box>
                      <Text
                        fontWeight="700"
                        pb={"16px"}
                        fontSize="18px"
                        color="#000000"
                        textAlign={"right"}
                      >
                        {myevent.name}
                      </Text>
                      <Flex justifyContent={"flex-end"}>
                        <Text
                          mr={"10px"}
                          fontSize="14px"
                          fontWeight="700"
                          color="#000000"
                        >
                          Status
                        </Text>
                        <Button
                          fontWeight={"400"}
                          letterSpacing={"1.4px"}
                          fontSize={"12px"}
                          size={"sm"}
                          colorScheme="green"
                          variant={"outline"}
                          fontStyle={"italic"}
                          borderRadius={"8px"}
                          height={"25px"}
                        >
                          Ongoing
                        </Button>
                      </Flex>
                    </Box>
                  </Flex>
                  <Flex mb={"15px"} justifyContent={"space-between"}>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Event Name
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myevent.name}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Event Category
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myevent.category.name}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex mb={"15px"} justifyContent={"space-between"}>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Event Theme
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myevent.theme}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Event Visibility
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myevent.visibility}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex mb={"15px"} justifyContent={"space-between"}>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Date
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {getFullDate(myevent.date)}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="14px" fontWeight="700" color="#000000">
                        Event Duration
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#000000">
                        {myevent.duration} hours
                      </Text>
                    </Box>
                  </Flex>
                  <Flex justifyContent={"flex-end"}>
                    <Box>
                      <Text
                        textAlign={"right"}
                        mb={"15px"}
                        fontSize="14px"
                        fontWeight="700"
                        color="#000000"
                      >
                        Actions
                      </Text>
                      <HStack spacing={"15px"}>
                        <Center
                          cursor={"pointer"}
                          onClick={() => {
                            viewEvent(myevent.id);
                          }}
                        >
                          <FiEye fontSize={"22px"} />
                        </Center>
                        <Center
                          cursor={"pointer"}
                          onClick={() => {
                            editEvent(myevent.id);
                          }}
                        >
                          <FiEdit fontSize={"22px"} />
                        </Center>
                        <Center
                          cursor={"pointer"}
                          onClick={() => {
                            openDeleteEvent(myevent.id, myevent.name);
                          }}
                        >
                          <MdDelete fontSize={"22px"} />
                        </Center>
                      </HStack>
                    </Box>
                  </Flex>
                </Box>
              ))
            ) : (
              <Box>No data</Box>
            )}
          </Box>
          <Portal>
            <AlertDialog
              isOpen={isOpenAlert}
              leastDestructiveRef={cancelRef}
              onClose={onCloseAlert}
              size={"sm"}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="md" fontWeight="semibold">
                    Delete {eventName}
                  </AlertDialogHeader>

                  <AlertDialogBody fontSize={15}>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button size={"sm"} ref={cancelRef} onClick={onCloseAlert}>
                      Cancel
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme="red"
                      onClick={deletEvent}
                      ml={3}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Portal>
        </Box>
      )}
    </>
  );
};

export default ViewEvents;

import React, { useEffect, useState } from "react";
import ListHeader from "./ListHeader";
import { FaAngleRight, FaStar } from "react-icons/fa";
import vendorsService from "../../../services/vendors/vendors.service";
import { IVendorsData } from "../../../models/vendors/vendors.model";
import Loading from "../../../components/Loading";
const VendorsList = () => {
  const [vendors, setVendors] = useState<IVendorsData[]>([]);
  const goldStar = <FaStar className="text-[#F6BE1D]" />;
  const star = <FaStar className="text-[#130c0c]" />;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    vendorsService
      .getVendors()
      .then((data) => {
        setVendors(data.data);
        setLoading(false);
      })
      .catch((err) => err);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div className="px-5 lg:px-0 flex flex-col gap-5">
      <ListHeader />
      <div className="hidden bg-lightPurple lg:flex w-full gap-8 overflow-x-hidden py-3 px-10 text-sm font-semibold">
        <div className="flex gap-x-4">
          <input type="checkbox" />
          <span>Select All</span>
        </div>
        <h3 className="flex-1">Vendors</h3>
        <h3 className="flex-1">Rating</h3>
        <h3 className="flex-1">Category</h3>
      </div>
      {vendors &&
        vendors.map((vendor: IVendorsData) => (
          <div key={vendor.id} className="hover:bg-slate-200 cursor-pointer">
            <div className="hidden lg:flex gap-10 px-10 w-full [&>*]:h-fit [&>*]:self-center">
              <div className="px-10">
                <input type="checkbox" />
              </div>
              <div className="flex flex-1 gap-4 [&>*]:self-center">
                <img
                  src="/images/vendor-demo.png"
                  alt=""
                  className="rounded-xl h-14 w-14"
                />
                <h1>{vendor.business_name}</h1>
              </div>
              <div className="flex flex-1 gap-1">
                {goldStar}
                {goldStar}
                {goldStar}
                {goldStar}
                {star}
              </div>
              <h1 className="flex-1 w-full">
                {vendor.services.map((service) => service.name).join(", ")}
              </h1>
            </div>
            <div className="flex lg:hidden gap-4">
              <div className="flex flex-1 gap-4 [&>*]:self-center">
                <img
                  src="/images/vendor-demo.png"
                  alt=""
                  className="rounded-xl h-16 w-16"
                />
                <div className="flex flex-col gap-1">
                  <h1>{vendor.business_name}</h1>
                  <div className="flex flex-1 gap-1">
                    {goldStar}
                    {goldStar}
                    {goldStar}
                    {goldStar}
                    {star}
                  </div>
                </div>
              </div>
              <label className="self-center bg-green-500 text-white text-sm px-4 py-1 rounded-2xl">
                ONLINE
              </label>
              <FaAngleRight className="self-center text-xl" />
            </div>
          </div>
        ))}
    </div>
  );
};

export default VendorsList;

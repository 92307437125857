import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.css';
import { AdminContainer } from "./components/admin/AdminContainer";
import UserRoutes from "./pages/routes";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import EmailSent from "./pages/auth/EmailSent";
import EmailVerified from "./pages/auth/EmailVerified";
import SetPassword from "./pages/auth/SetPassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Dashboard from "./pages/user/Dashboard";
import ViewEvents from "./pages/user/Events/ViewEvents";
import CreateEvent from "./pages/user/Events/CreateEvent";
import AnEvent from "./pages/user/Events/AnEvent";
import EditEvent from "./pages/user/Events/EditEvent";
import VendorsList from "./pages/user/Vendors/VendorsList";

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: UserRoutes.Login,
    element: <Login />,
  },
  {
    path: UserRoutes.Register,
    element: <Register />,
  },
  {
    path: UserRoutes.emailSent,
    element: <EmailSent />,
  },
  {
    path: UserRoutes.emailVerified,
    element: <EmailVerified />,
  },
  {
    path: UserRoutes.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: UserRoutes.setPassword,
    element: <SetPassword />,
  },

  {
    path: '/partigram/',
    element: <AdminContainer />,
 
    children: [
      {
        path: UserRoutes.portal.home,
        element: <Dashboard />,
      },{
        path: UserRoutes.portal.vendors,
        element: <VendorsList />,
      },
      {
        path: UserRoutes.portal.viewEvents,
        element: <ViewEvents />,
      },
      {
        path: UserRoutes.portal.createEvent,
        element: <CreateEvent />,
      },
      {
        path: UserRoutes.portal.viewAnEvent,
        element: <AnEvent />,
      },
      {
        path: UserRoutes.portal.editEvent,
        element: <EditEvent />,
      },
     
    ],
  },
]);
function App() {

  return <RouterProvider router={router} />;

}

export default App;

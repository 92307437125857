import {
  Flex,
  HStack,
  Image,
  Box,
  Link,
  Text,
  Container,
  Spacer,
  Avatar,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  Button,
} from "@chakra-ui/react";
import bgWeb from "../../img/bgDashboard.png";
import bgMobile from "../../img/bgDashboardMobile.png";
import metricBg from "../../img/metricBg.png";
import metricBg2 from "../../img/metbg4.png";
import metricBg3 from "../../img/met3.png";
import UserRoutes from "../routes";
import { PiCalendarPlus } from "react-icons/pi";
import { BsArrowUp } from "react-icons/bs";
import eventImg from "../../img/eventList.png";
import lady from "../../img/lady.png";
import { FaStar, FaRegStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const router = useNavigate()
  return (
    <>
      <Box
        bgImage={[bgMobile, bgMobile, bgWeb, bgWeb]}
        backgroundSize={"cover"}
        backgroundPosition={"center top"}
        backgroundRepeat={"no-repeat"}
        justifyContent={"space-between"}
        px={["20px", "20px", "50px", "50px"]}
        pt={["20px", "25px", "40px", "50px"]}
        pb={["20px", "20px", "40px", "40px"]}
        className="linear_gradient"
        borderRadius={["0px", "0px", "16px", "16px"]}
      >
        <Flex justifyContent={"flex-end"}>
          <HStack
            pl={"10px"}
            spacing="10px"
            mb="15px"
            bg={"white"}
            cursor={"pointer"}
            fontWeight={"500"}
            px={"24px"}
            py={"10px"}
            onClick={() => {router(UserRoutes.portal.createEvent)}}
            borderRadius={"24px"}
          >
            <Box as="span" pb="3px">
              <PiCalendarPlus fontSize={"30px"} />
            </Box>

            <Box
              mb="3px"
              mt="4px"
              pb="5px"
              pt={"2px"}
              fontSize={["13px", "13px", "14px", "14px"]}
              width="100%"
            >
              Create New Event
            </Box>
          </HStack>
        </Flex>
        <Box mt={["60px", "70px", "80px", "90px"]}>
          <Text
            fontSize={["24px", "28px", "32px", "37px"]}
            fontWeight={"600"}
            color={"white"}
            fontFamily={"Roboto, serif"}
          >
            Welcome back, Kunle!
          </Text>
          <Text
            fontSize={["14px", "16px", "16px", "18px"]}
            fontWeight={"600"}
            color={"white"}
          >
            Here is an overview of your Dashboard
          </Text>
        </Box>
      </Box>
      <Flex
        justifyContent="space-between"
        flexDirection={["column", "column", "row", "row"]}
        px={["20px", "20px", "0px", "0px"]}
        pt={["10px", "10px", "30px", "30px"]}
        mb="20px"
      >
        <Container
          bgImage={metricBg}
          backgroundSize={"cover"}
          backgroundPosition={"center top"}
          backgroundRepeat={"no-repeat"}
          pt={["30px", "30px", "27px", "27px"]}
          py={["20px", "20px", "20px", "20px"]}
          mb={["30px", "30px", "0px", "0px"]}
          borderRadius={"13.5px"}
        >
          <Box pb={3} pt={1}>
            <Text color="#353535" fontSize={["18px", "18px", "16px", "16px"]}>
              Total Events Created
            </Text>
            <Text
              color="#747474"
              fontWeight="semibold"
              fontSize={["40px", "40px", "36px", "36px"]}
            >
              0
            </Text>
            <HStack>
              <BsArrowUp color="green" fontSize={"12px"} />
              <Text ml={"-10px"} color="green" fontSize={"12px"}>
                15%
              </Text>
              <Text color="#929292" fontSize={"12px"}>
                Last month
              </Text>
            </HStack>
          </Box>
        </Container>

        <Spacer mx={3} />
        <Container
          borderRadius={"13.5px"}
          bgImage={metricBg2}
          backgroundSize={"cover"}
          backgroundPosition={"center top"}
          backgroundRepeat={"no-repeat"}
          pt={["30px", "30px", "27px", "27px"]}
          py={["20px", "20px", "20px", "20px"]}
          mb={["30px", "30px", "0px", "0px"]}
        >
          <Box pb={3} pt={1}>
            <Text color="#353535" fontSize={["18px", "18px", "16px", "16px"]}>
              Total Budget
            </Text>
            <Text
              color="#747474"
              fontWeight="semibold"
              fontSize={["40px", "40px", "36px", "36px"]}
            >
              ₦852,000
            </Text>
            <HStack>
              <BsArrowUp color="green" fontSize={"12px"} />
              <Text ml={"-10px"} color="green" fontSize={"12px"}>
                15%
              </Text>
              <Text color="#929292" fontSize={"12px"}>
                Last month
              </Text>
            </HStack>
          </Box>
        </Container>

        <Spacer mx={3} />
        <Container
          bgImage={metricBg}
          borderRadius={"13.5px"}
          backgroundSize={"cover"}
          backgroundPosition={"center top"}
          backgroundRepeat={"no-repeat"}
          pt={["30px", "30px", "27px", "27px"]}
          py={["20px", "20px", "20px", "20px"]}
          mb={["30px", "30px", "0px", "0px"]}
        >
          <Box pb={3} pt={1}>
            <HStack spacing={3}>
              <Text color="#353535" fontSize={["18px", "18px", "16px", "16px"]}>
                Total Vendors Hired
              </Text>
            </HStack>
            <Text
              color="#747474"
              fontWeight="semibold"
              fontSize={["40px", "40px", "36px", "36px"]}
            >
              0{" "}
            </Text>
            <HStack>
              <BsArrowUp color="green" fontSize={"12px"} />
              <Text ml={"-10px"} color="green" fontSize={"12px"}>
                15%
              </Text>
              <Text color="#929292" fontSize={"12px"}>
                Last month
              </Text>
            </HStack>
          </Box>
        </Container>

        <Spacer mx={3} />

        <Container
          bgImage={metricBg3}
          borderRadius={"13.5px"}
          backgroundSize={"cover"}
          backgroundPosition={"center top"}
          backgroundRepeat={"no-repeat"}
          pt={["30px", "30px", "27px", "27px"]}
          py={["20px", "20px", "20px", "20px"]}
          mb={["30px", "30px", "0px", "0px"]}
        >
          <Box pb={3} pt={1}>
            <Text color="#353535" fontSize={["18px", "18px", "16px", "16px"]}>
              {" "}
              Total Vendors Paid
            </Text>
            <Text
              color="#747474"
              fontWeight="semibold"
              fontSize={["40px", "40px", "36px", "36px"]}
            >
              0
            </Text>
            <HStack>
              <BsArrowUp color="green" fontSize={"12px"} />
              <Text ml={"-10px"} color="green" fontSize={"12px"}>
                15%
              </Text>
              <Text color="#929292" fontSize={"12px"}>
                Last month
              </Text>
            </HStack>
          </Box>
        </Container>
      </Flex>
      <Flex
        display={["none", "none", "flex", "flex"]}
        mt="20px"
        mb="20px"
        justifyContent={"space-between"}
      >
        <Box
          px={"25px"}
          borderRadius={"8px"}
          pt="25px"
          pb={"30px"}
          bg={"#F9F2FF"}
          width={"49%"}
        >
          <Flex pr="20px" justifyContent={"space-between"}>
            <Text fontWeight="700" fontSize="16px" color="#000000">
              Featured Events
            </Text>
          </Flex>

          <TableContainer>
            <Table bg={"#F9F2FF"}>
              <Thead>
                <Tr
                  bg={"#F9F2FF"}
                  fontSize={"13px"}
                  fontWeight={"500"}
                  color={"#929292"}
                >
                  <Td textAlign={"left"}>EVENT DETAILS </Td>
                  <Td>GUESTS</Td>
                  <Td>OPEN TO</Td>
                </Tr>
              </Thead>
              <Tbody>
                <Tr bg={"#F9F2FF"}>
                  <HStack py={"10px"} spacing="5px" mt={"10px"}>
                    <Image
                      src={eventImg}
                      borderRadius={"12px"}
                      width={"90px"}
                      height={"65px"}
                    />
                    <Box>
                      <Text
                        fontWeight="700"
                        textTransform="capitalize"
                        fontSize="14px"
                        color="#2D3748"
                      >
                        Glo end of year party
                      </Text>
                      <Text
                        fontWeight="400"
                        textTransform="capitalize"
                        fontSize="14px"
                        color="#718096"
                        mt={"-4px"}
                      >
                        Soul2Soul Events
                      </Text>
                      <Text
                        fontWeight="400"
                        mt={"10px"}
                        fontSize="10px"
                        color="#718096"
                      >
                        Click here for more details
                      </Text>
                    </Box>
                  </HStack>
                  <Td>
                    {" "}
                    <Text
                      textAlign={"center"}
                      fontWeight="700"
                      textTransform="capitalize"
                      fontSize="14px"
                      color="#353535"
                    >
                      1500
                    </Text>
                  </Td>
                  <Td>
                    {" "}
                    <Text
                      fontWeight="700"
                      textAlign={"center"}
                      textTransform="uppercase"
                      fontSize="10px"
                      color="#4FD1C5"
                    >
                      PUBLIC
                    </Text>
                  </Td>
                </Tr>
                <Tr bg={"#F9F2FF"}>
                  <HStack py={"10px"} spacing="5px" mt={"10px"}>
                    <Image
                      src={eventImg}
                      borderRadius={"12px"}
                      width={"90px"}
                      height={"65px"}
                    />
                    <Box>
                      <Text
                        fontWeight="700"
                        textTransform="capitalize"
                        fontSize="14px"
                        color="#2D3748"
                      >
                        Glo end of year party
                      </Text>
                      <Text
                        fontWeight="400"
                        textTransform="capitalize"
                        fontSize="14px"
                        color="#718096"
                      >
                        Soul2Soul Events
                      </Text>
                      <Text
                        fontWeight="400"
                        mt={"10px"}
                        fontSize="10px"
                        color="#718096"
                      >
                        Click here for more details
                      </Text>
                    </Box>
                  </HStack>
                  <Td>
                    {" "}
                    <Text
                      textAlign={"center"}
                      fontWeight="700"
                      textTransform="capitalize"
                      fontSize="14px"
                      color="#353535"
                    >
                      1500
                    </Text>
                  </Td>
                  <Td>
                    {" "}
                    <Text
                      fontWeight="700"
                      textAlign={"center"}
                      textTransform="uppercase"
                      fontSize="10px"
                      color="#4FD1C5"
                    >
                      PUBLIC
                    </Text>
                  </Td>
                </Tr>
                <Tr bg={"#F9F2FF"}>
                  <HStack py={"10px"} spacing="5px" mt={"10px"}>
                    <Image
                      src={eventImg}
                      borderRadius={"12px"}
                      width={"90px"}
                      height={"65px"}
                    />
                    <Box>
                      <Text
                        fontWeight="700"
                        textTransform="capitalize"
                        fontSize="14px"
                        color="#2D3748"
                      >
                        Glo end of year party
                      </Text>
                      <Text
                        fontWeight="400"
                        textTransform="capitalize"
                        fontSize="14px"
                        color="#718096"
                      >
                        Soul2Soul Events
                      </Text>
                      <Text
                        fontWeight="400"
                        mt={"10px"}
                        fontSize="10px"
                        color="#718096"
                      >
                        Click here for more details
                      </Text>
                    </Box>
                  </HStack>
                  <Td>
                    {" "}
                    <Text
                      textAlign={"center"}
                      fontWeight="700"
                      textTransform="capitalize"
                      fontSize="14px"
                      color="#353535"
                    >
                      1500
                    </Text>
                  </Td>
                  <Td>
                    {" "}
                    <Text
                      fontWeight="700"
                      textAlign={"center"}
                      textTransform="uppercase"
                      fontSize="10px"
                      color="#4FD1C5"
                    >
                      PUBLIC
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          px={"25px"}
          borderRadius={"8px"}
          pt="25px"
          pb={"30px"}
          bg={"#F9F2FF"}
          width={"49%"}
        >
          <Flex pr="20px" justifyContent={"space-between"}>
            <Text fontWeight="700" fontSize="16px" color="#000000">
              Top Ranked Vendors
            </Text>
          </Flex>

          <TableContainer>
            <Table bg={"#F9F2FF"}>
              <Tbody>
                <Tr bg={"#F9F2FF"}>
                  <HStack py={"10px"} spacing="5px" mt={"10px"}>
                    <Image
                      src={lady}
                      borderRadius={"12px"}
                      width={"50px"}
                      height={"50px"}
                    />
                    <Box>
                      <Text
                        fontWeight="700"
                        textTransform="capitalize"
                        fontSize="14px"
                        color="#2D3748"
                      >
                        Bolaji Muili
                      </Text>
                      <Text
                        fontWeight="400"
                        textTransform="capitalize"
                        fontSize="12px"
                        color="#718096"
                        mt={"-4px"}
                      >
                        Soul2Soul Events
                      </Text>
                    </Box>
                  </HStack>
                  <Td>
                    {" "}
                    <Text
                      textAlign={"center"}
                      fontWeight="700"
                      textTransform="capitalize"
                      fontSize="14px"
                      color="#353535"
                    >
                      <HStack>
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaRegStar fontSize={"18px"} />
                      </HStack>
                    </Text>
                  </Td>
                  <Td>
                    {" "}
                    <Text
                      fontWeight="700"
                      textAlign={"center"}
                      textTransform="uppercase"
                      fontSize="10px"
                      color="#4FD1C5"
                    >
                      CHAT
                    </Text>
                  </Td>
                </Tr>
                <Tr bg={"#F9F2FF"}>
                  <HStack py={"10px"} spacing="5px" mt={"10px"}>
                    <Image
                      src={lady}
                      borderRadius={"12px"}
                      width={"50px"}
                      height={"50px"}
                    />
                    <Box>
                      <Text
                        fontWeight="700"
                        textTransform="capitalize"
                        fontSize="14px"
                        color="#2D3748"
                      >
                        Bolaji Muili
                      </Text>
                      <Text
                        fontWeight="400"
                        textTransform="capitalize"
                        fontSize="12px"
                        color="#718096"
                        mt={"-4px"}
                      >
                        Soul2Soul Events
                      </Text>
                    </Box>
                  </HStack>
                  <Td>
                    {" "}
                    <Text
                      textAlign={"center"}
                      fontWeight="700"
                      textTransform="capitalize"
                      fontSize="14px"
                      color="#353535"
                    >
                      <HStack>
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaRegStar fontSize={"18px"} />
                      </HStack>
                    </Text>
                  </Td>
                  <Td>
                    {" "}
                    <Text
                      fontWeight="700"
                      textAlign={"center"}
                      textTransform="uppercase"
                      fontSize="10px"
                      color="#4FD1C5"
                    >
                      CHAT
                    </Text>
                  </Td>
                </Tr>
                <Tr bg={"#F9F2FF"}>
                  <HStack py={"10px"} spacing="5px" mt={"10px"}>
                    <Image
                      src={lady}
                      borderRadius={"12px"}
                      width={"50px"}
                      height={"50px"}
                    />
                    <Box>
                      <Text
                        fontWeight="700"
                        textTransform="capitalize"
                        fontSize="14px"
                        color="#2D3748"
                      >
                        Bolaji Muili
                      </Text>
                      <Text
                        fontWeight="400"
                        textTransform="capitalize"
                        fontSize="12px"
                        color="#718096"
                        mt={"-4px"}
                      >
                        Soul2Soul Events
                      </Text>
                    </Box>
                  </HStack>
                  <Td>
                    {" "}
                    <Text
                      textAlign={"center"}
                      fontWeight="700"
                      textTransform="capitalize"
                      fontSize="14px"
                      color="#353535"
                    >
                      <HStack>
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaRegStar fontSize={"18px"} />
                      </HStack>
                    </Text>
                  </Td>
                  <Td>
                    {" "}
                    <Text
                      fontWeight="700"
                      textAlign={"center"}
                      textTransform="uppercase"
                      fontSize="10px"
                      color="#4FD1C5"
                    >
                      CHAT
                    </Text>
                  </Td>
                </Tr>
                <Tr bg={"#F9F2FF"}>
                  <HStack py={"10px"} spacing="5px" mt={"10px"}>
                    <Image
                      src={lady}
                      borderRadius={"12px"}
                      width={"50px"}
                      height={"50px"}
                    />
                    <Box>
                      <Text
                        fontWeight="700"
                        textTransform="capitalize"
                        fontSize="14px"
                        color="#2D3748"
                      >
                        Bolaji Muili
                      </Text>
                      <Text
                        fontWeight="400"
                        textTransform="capitalize"
                        fontSize="12px"
                        color="#718096"
                        mt={"-4px"}
                      >
                        Soul2Soul Events
                      </Text>
                    </Box>
                  </HStack>
                  <Td>
                    {" "}
                    <Text
                      textAlign={"center"}
                      fontWeight="700"
                      textTransform="capitalize"
                      fontSize="14px"
                      color="#353535"
                    >
                      <HStack>
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaStar color="gold" fontSize={"18px"} />
                        <FaRegStar fontSize={"18px"} />
                      </HStack>
                    </Text>
                  </Td>
                  <Td>
                    {" "}
                    <Text
                      fontWeight="700"
                      textAlign={"center"}
                      textTransform="uppercase"
                      fontSize="10px"
                      color="#4FD1C5"
                    >
                      CHAT
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Flex>
      <Box display={["block", "block", "none", "none"]} mt="20px" mb="20px">
        <Box
          px={"15px"}
          py={"27px"}
          mx={"20px"}
          mb={"4px"}
          borderRadius={"8px"}
          bg={"#F9F2FF"}
        >
          <Text
            fontWeight="700"
            mb={"10px"}
            pb={"16px"}
            borderBottom={"1px solid white"}
            fontSize="16px"
            color="#000000"
          >
            Top Ranked Vendors
          </Text>
          <Box>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <HStack py={"5px"} spacing="5px">
                <Image
                  src={lady}
                  borderRadius={"12px"}
                  width={"50px"}
                  height={"50px"}
                />
                <Box>
                  <Text
                    fontWeight="700"
                    textTransform="capitalize"
                    fontSize="14px"
                    color="#2D3748"
                  >
                    Bolaji Muili
                  </Text>
                  <Text
                    fontWeight="400"
                    textTransform="capitalize"
                    fontSize="12px"
                    color="#718096"
                    mt={"-2px"}
                  >
                    Soul2Soul Events
                  </Text>

                  <HStack mt={"10px"}>
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaRegStar fontSize={"14px"} />
                  </HStack>
                </Box>
              </HStack>
              <Button
                color={"white"}
                borderRadius={"12px"}
                height={"37px"}
                px={"12px"}
                bg={"#583A76"}
                fontSize={"12px"}
              >
                CHAT
              </Button>
            </Flex>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <HStack py={"5px"} spacing="5px">
                <Image
                  src={lady}
                  borderRadius={"12px"}
                  width={"50px"}
                  height={"50px"}
                />
                <Box>
                  <Text
                    fontWeight="700"
                    textTransform="capitalize"
                    fontSize="14px"
                    color="#2D3748"
                  >
                    Bolaji Muili
                  </Text>
                  <Text
                    fontWeight="400"
                    textTransform="capitalize"
                    fontSize="12px"
                    color="#718096"
                    mt={"-2px"}
                  >
                    Soul2Soul Events
                  </Text>

                  <HStack mt={"10px"}>
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaRegStar fontSize={"14px"} />
                  </HStack>
                </Box>
              </HStack>
              <Button
                color={"white"}
                borderRadius={"12px"}
                height={"37px"}
                px={"12px"}
                bg={"#583A76"}
                fontSize={"12px"}
              >
                CHAT
              </Button>
            </Flex>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <HStack py={"5px"} spacing="5px">
                <Image
                  src={lady}
                  borderRadius={"12px"}
                  width={"50px"}
                  height={"50px"}
                />
                <Box>
                  <Text
                    fontWeight="700"
                    textTransform="capitalize"
                    fontSize="14px"
                    color="#2D3748"
                  >
                    Bolaji Muili
                  </Text>
                  <Text
                    fontWeight="400"
                    textTransform="capitalize"
                    fontSize="12px"
                    color="#718096"
                    mt={"-2px"}
                  >
                    Soul2Soul Events
                  </Text>

                  <HStack mt={"10px"}>
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaStar color="gold" fontSize={"14px"} />
                    <FaRegStar fontSize={"14px"} />
                  </HStack>
                </Box>
              </HStack>
              <Button
                color={"white"}
                borderRadius={"12px"}
                height={"37px"}
                px={"12px"}
                bg={"#583A76"}
                fontSize={"12px"}
              >
                CHAT
              </Button>
            </Flex>
          </Box>
        </Box>
        <Box
          px={"15px"}
          py={"27px"}
          mx={"20px"}
          mb={"4px"}
          borderRadius={"8px"}
          bg={"#F9F2FF"}
        >
          <Text
            fontWeight="700"
            mb={"10px"}
            pb={"16px"}
            borderBottom={"1px solid white"}
            fontSize="16px"
            color="#000000"
          >
            Featured Events
          </Text>
          <Box>
            <HStack py={"5px"} spacing="5px">
              <Image
                src={eventImg}
                borderRadius={"12px"}
                width={"90px"}
                height={"65px"}
              />
              <Box>
                <Text
                  fontWeight="700"
                  textTransform="capitalize"
                  fontSize="14px"
                  color="#2D3748"
                >
                  Glo End of Year Party
                </Text>
                <Text
                  fontWeight="400"
                  textTransform="capitalize"
                  fontSize="12px"
                  color="#718096"
                  mt={"-2px"}
                >
                  Soul2Soul Events
                </Text>

                <Text
                  fontWeight="400"
                  mt={"10px"}
                  fontSize="10px"
                  color="#718096"
                >
                  Click here for more details
                </Text>
              </Box>
            </HStack>
            <HStack py={"5px"} spacing="5px">
              <Image
                src={eventImg}
                borderRadius={"12px"}
                width={"90px"}
                height={"65px"}
              />
              <Box>
                <Text
                  fontWeight="700"
                  textTransform="capitalize"
                  fontSize="14px"
                  color="#2D3748"
                >
                  Glo End of Year Party
                </Text>
                <Text
                  fontWeight="400"
                  textTransform="capitalize"
                  fontSize="12px"
                  color="#718096"
                  mt={"-2px"}
                >
                  Soul2Soul Events
                </Text>

                <Text
                  fontWeight="400"
                  mt={"10px"}
                  fontSize="10px"
                  color="#718096"
                >
                  Click here for more details
                </Text>
              </Box>
            </HStack>
            <HStack py={"5px"} spacing="5px">
              <Image
                src={eventImg}
                borderRadius={"12px"}
                width={"90px"}
                height={"65px"}
              />
              <Box>
                <Text
                  fontWeight="700"
                  textTransform="capitalize"
                  fontSize="14px"
                  color="#2D3748"
                >
                  Glo End of Year Party
                </Text>
                <Text
                  fontWeight="400"
                  textTransform="capitalize"
                  fontSize="12px"
                  color="#718096"
                  mt={"-2px"}
                >
                  Soul2Soul Events
                </Text>

                <Text
                  fontWeight="400"
                  mt={"10px"}
                  fontSize="10px"
                  color="#718096"
                >
                  Click here for more details
                </Text>
              </Box>
            </HStack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;

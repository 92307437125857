import React, { useRef, useEffect, useState, ChangeEvent, KeyboardEvent } from 'react';

const correctOTP = "1234"; // validate from your server

interface OtpInputWithValidationProps {
  numberOfDigits: number;
  valueOtp : any
}

function OtpInputWithValidation({ numberOfDigits ,valueOtp }: OtpInputWithValidationProps): JSX.Element {
  const [otp, setOtp] = useState<string[]>(new Array(numberOfDigits).fill(""));
  console.log(otp.join(''),'otp')
  const [otpError, setOtpError] = useState<string | null>(null);
  const otpBoxReference = useRef<HTMLInputElement[]>([]);
 
 
  function handleChange(value: string, index: number) {
    const newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);
if(newArr.length == 4){
  valueOtp(newArr.join(''))
}
    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e: KeyboardEvent<HTMLInputElement>, index: number) {
    if (e.key === "Backspace" && !e.currentTarget.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.currentTarget.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

 

  return (
    <article className="w-1/2">
      {/* ... other JSX code */}
      
      <div className='otp-container'>
        {otp.map((digit, index) => (
          <input key={index} value={digit} maxLength={1}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, index)}
            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => handleBackspaceAndEnter(e, index)}
            ref={(reference) => { if (reference) otpBoxReference.current[index] = reference; }}
            className={`otp-input ${otpError ? 'error-show' : ''}`}
          />
        ))}
      </div>

      <p className={`text-lg text-white mt-4 ${otpError ? 'error-show' : ''}`}>{otpError}</p>
    </article>
  );
}

export default OtpInputWithValidation;

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Portal,
} from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import AdminDashboardHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import { useEffect, useState } from "react";
import {
  destroyUserSession,
  getAuthToken,
} from "../../services/auth/auth.service";
import UserRoutes from "../../pages/routes";

export const AdminContainer = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const router = useNavigate();
  const [token, setToken] = useState<boolean>();
  const [refreshToken, setRefreshToken] = useState("");
  const [logoutTimer, setLogoutTimer] = useState<any>(null);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const [isOpenAlert, setIsOpen] = useState(false);
  const onCloseAlert = () => setIsOpen(false);
  useEffect(() => {
    if (!getAuthToken()) handleLogout();
  }, []);
  const handleLogout = () => {
    destroyUserSession();
    router(UserRoutes.Login);
  };
  return (
    <>
      <Flex className="adminCont">
        <Box
          className="AdminSideBar"
          w={
            sidebarOpen
              ? ["0px", "0px", "82px", "18%"]
              : ["0px", "0px", "82px", "82px"]
          }
          position="fixed"
          top="0"
          left="0"
          display={["none", "none", "block", "block"]}
          zIndex="1"
        >
          <AdminSidebar toggleFunct={toggleSidebar} toggle={sidebarOpen} />
        </Box>
        <Box
          className="AdminContent"
          width={
            sidebarOpen
              ? ["100%", "100%", "calc(100% - 82px)", "82%"]
              : ["100%", "100%", "calc(100% - 82px)", "calc(100% - 82px)"]
          }
          ml={
            sidebarOpen
              ? ["0px", "0px", "82px", "18%"]
              : ["0px", "0px", "82px", "82px"]
          }
        >
          <AdminDashboardHeader
            toggleFunct={toggleSidebar}
            toggle={sidebarOpen}
          />
          <Box
            bg="white"
            minHeight="90vh"
            height="auto"
            pb="1rem"
            pt="30px"
            px={["0px", "0px", "20px", "20px"]}
          >
            <Outlet />
          </Box>
        </Box>
      </Flex>
    </>
  );
};



import { ILoginReq, ILoginRes } from "../../models/auth/login.model";
import { IRegRes, IRegUser, IUserRegRequest } from "../../models/auth/userRegister.model";
import { IVendorRegRequest } from "../../models/auth/vendorRegister.model";
import postService from "../crud-requests/postService";

const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL ;

export const destroyUserSession = () => {
  sessionStorage.removeItem("partigram_user_token");
  sessionStorage.removeItem("partigram_user_user");
};

export const getAuthToken = (): string | null => {
  const token = sessionStorage.getItem("partigram_user_token")?.toString();
  console.log(token,'token')
   if (token) {
    return token;
  }
  return "";
};


class AuthService {
 
  async login(data: ILoginReq) : Promise<ILoginRes> {
    const response = await postService.post(
      `${CONFIG_API_URL}auth/login`,
      data
    );
    return response.data;
  }

  async register(data: IUserRegRequest | IVendorRegRequest) : Promise<IRegRes> {
    const response = await postService.post(
      `${CONFIG_API_URL}auth/register`,
      data
    );
    return response.data;
  }
  async forgot_password(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}auth/password/forgot`,
      data
    );

    return response.data;
  }
  async reset_password(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}auth/password/reset`,
      data
    );

    return response.data;
  }
  async resetPassword(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}auth/password/reset`,
      data
    );
    return response.data;
  } 
  async verifyOTP(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}auth/password/check-otp`,
      data
    );
    return response.data;
  } 
  async verify(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}auth/verify`,
      data
    );
    return response.data;
  } 
  async resendCode(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}auth/verify/resend`,
      data
    );
    return response.data;
  } 

}

export default new AuthService();

import React from 'react'
import CraftingEvents from '../components/landingPage/CraftingEvents'
import Header from '../components/common/Header'

const LandingPage = () => {
  return (
    <>
    <Header />
    <CraftingEvents />
    </>
  )
}

export default LandingPage
import { ICategoriesRes } from "../../models/categories/categories.model";
import { IServiceRes } from "../../models/services/service.model";
import getService from "../crud-requests/getService";


const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL;

class CommonService {
    async getServices() : Promise<IServiceRes> {
        const response = await getService.get(
            `${CONFIG_API_URL}services`,
        );
        return response.data;
    }
    async getCatgories() :Promise<ICategoriesRes> {
        const response = await getService.get(
            `${CONFIG_API_URL}categories`,
        );
        return response.data;
    }
    async getCurrencies() :Promise<any> {
        const response = await getService.get(
            `${CONFIG_API_URL}currencies`,
        );
        return response.data;
    }
    

}

export default new CommonService();
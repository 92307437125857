import React, { useState, useEffect } from "react";
import { BiX } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import vendorsService from "../../../services/vendors/vendors.service";
import { IVendorsData } from "../../../models/vendors/vendors.model";
import Loading from "../../../components/Loading";

interface HireVendorProps {
  categoryId: string;
  serviceId: string;
}
function HireVendor({ categoryId, serviceId }: HireVendorProps) {
  const [vendors, setVendors] = useState<IVendorsData[]>([]);
  const [showTab, setShowTab] = useState(true);
  const goldStar = <FaStar className="text-[#F6BE1D]" />;
  const star = <FaStar className="text-[#D9D9D9]" />;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    vendorsService
      .getVendors() //Change to .hireVendors(categoryId, serviceId) later
      .then((data) => {
        setVendors(data.data);
        setLoading(false);
      })
      .catch((err) => err);
  }, []);
  return showTab ? (
    <div className="h-screen w-full fixed top-0 left-0 overflow-hidden flex flex-col justify-center z-20 bg-black bg-opacity-60">
      <div className="bg-white relative mx-auto w-10/12 lg:w-7/12 rounded-lg p-4 lg:p-8 space-y-5">
        <div className="flex justify-between border-b-2 py-4">
          <h1 className="text-xl font-semibold">Hire A Vendor</h1>
          <BiX
            className="text-3xl self-center cursor-pointer duration-300"
            onClick={(e) => setShowTab(false)}
          />
        </div>
        <div className="hidden bg-lightPurple lg:flex gap-8 overflow-x-hidden py-5 px-5 text-sm font-semibold">
          <input type="checkbox" />
          <h3 className="flex-1">Vendors</h3>
          <h3 className="flex-1">Rating</h3>
          <h3 className="flex-1">Category</h3>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className="max-h-56 overflow-y-auto space-y-5">
            {vendors.map((vendor: IVendorsData) => (
              <div key={vendor.id}>
                <div className="hidden lg:flex gap-10 px-5 w-full [&>*]:h-fit [&>*]:self-center">
                  <input type="checkbox" />
                  <div className="flex flex-1 gap-4 [&>*]:self-center">
                    <img
                      src="/images/vendor-demo.png"
                      alt=""
                      className="rounded-xl h-14 w-14"
                    />
                    <h1>{vendor.business_name}</h1>
                  </div>
                  <div className="flex gap-1 text-sm">
                    {goldStar}
                    {goldStar}
                    {goldStar}
                    {goldStar}
                    {star}
                  </div>
                  <h1 className="flex-1 w-full">
                    {vendor.services.map((service) => service.name).join(", ")}
                  </h1>
                </div>
                <div className="flex justify-between px-5 lg:hidden gap-4">
                  <div className="flex gap-4">
                  <img
                    src="/images/vendor-demo.png"
                    alt=""
                    className="rounded-xl h-16 w-16"
                  />
                  <h1 className="flex-1 self-center w-full">
                    {vendor.business_name}
                  </h1>
                  </div>
                  <div className="flex self-center gap-1">
                    {goldStar}
                    {goldStar}
                    {goldStar}
                    {goldStar}
                    {star}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="flex justify-end">
          <button className="lg:py-3 rounded-lg py-2 w-full lg:w-fit px-5 font-semibold bg-darkPurple text-white">
            Request Quote
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default HireVendor;

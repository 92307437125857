import { useEffect, useState } from "react";
import {
  Avatar,
  AvatarBadge,
  Box,
  Collapse,
  Flex,
  HStack,
  Image,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbLayoutDashboard } from "react-icons/tb";
import UserRoutes from "../../pages/routes";
import { HiOutlineLogout } from "react-icons/hi";
import { PiCalendarPlus } from "react-icons/pi";
import { destroyUserSession } from "../../services/auth/auth.service";
import logo from "../../img/partilogo.png";
import { BsChatRightDots } from "react-icons/bs";

const AdminSidebar = ({
  toggle,
  toggleFunct
}: {
  toggle: boolean;
  toggleFunct: any;
}) => {
  let location = useLocation();
  const router = useNavigate();
  const [tooltipText, setTooltipText] = useState("");
  const handleMouseEnter = (text: string) => {
    setTooltipText(text);
  };

  const handleMouseLeave = () => {
    setTooltipText("");
  };
  useEffect(() => {
    const userLogged = sessionStorage.getItem("UserFromToken");
    if (userLogged) {
      const parsedUser = JSON.parse(userLogged);
    }
  }, []);

  const activeLink = (link: string) => {
    let style = {
      bgColor: "081C4D",
      textColor: "#FF11FF",
      iconColour: "#FF11FF",
      boxShadow: "none"
    };

    if (link === location.pathname) {
      style.bgColor = "white";
      style.textColor = "#000000";
      style.iconColour = "#000000";
      style.boxShadow = "0px 6.661px 10.468px 0px rgba(0, 0, 0, 0.02)";
    }

    return style;
  };
  const handleLogout = () => {
    destroyUserSession();
    router(UserRoutes.Login);
  };
  return (
    <Box
      bg="#583A76"
      px="10px"
      borderTopRightRadius={
        !toggle ? ["0px", "0px", "0px", "0px"] : ["0px", "0px", "0px", "24px"]
      }
      borderBottomRightRadius={
        !toggle ? ["0px", "0px", "0px", "0px"] : ["0px", "0px", "0px", "24px"]
      }
      minH="100vh"
      boxShadow={"-20px 0px 30px 10px rgba(0, 0, 0, 0.15) inset"}
    >
      <Flex
        pl={"10px"}
        justifyContent={["center", "center", "flex-start", "center"]}
        pt="15px"
        mb="35px"
        alignItems="center"
      >
        <Box as="span" height={"50px"}>
          <Image src={logo} height={"50px"} borderRadius={"10px"} />
        </Box>
      </Flex>

      <HStack
        pl={"10px"}
        spacing="10px"
        bg={activeLink(UserRoutes.portal.home).bgColor}
        color={activeLink(UserRoutes.portal.home).textColor}
        mb="15px"
        fontWeight={"500"}
        borderRadius={"0px 24px 24px 0px;"}
        onClick={() => {
          router(UserRoutes.portal.home);
        }}
        boxShadow={activeLink(UserRoutes.portal.home).boxShadow}
        cursor={"pointer"}
      >
        <Box as="span" pb="3px">
          <TbLayoutDashboard
            fontSize={"30px"}
            title="Dashboard"
            onMouseEnter={() => handleMouseEnter("Settings")}
            onMouseLeave={handleMouseLeave}
            color={activeLink(UserRoutes.portal.home).iconColour}
          />
        </Box>
        {toggle && (
          <Box
            display={["none", "none", "none", "block"]}
            mb="3px"
            mt="4px"
            pb="10px"
            pt={"10px"}
            fontSize="14px"
            width="100%"
          >
            <Link to={UserRoutes.portal.home}>Dashboard</Link>
          </Box>
        )}
      </HStack>
      <HStack
        pl={"10px"}
        spacing="10px"
        bg={activeLink(UserRoutes.portal.viewEvents).bgColor}
        color={activeLink(UserRoutes.portal.viewEvents).textColor}
        mb="15px"
        cursor={"pointer"}
        fontWeight={"500"}
        borderRadius={"0px 24px 24px 0px;"}
        onClick={() => {
          router(UserRoutes.portal.viewEvents);
        }}
        boxShadow={activeLink(UserRoutes.portal.viewEvents).boxShadow}
      >
        <Box as="span" pb="3px">
          <PiCalendarPlus
            fontSize={"30px"}
            color={activeLink(UserRoutes.portal.viewEvents).iconColour}
          />
        </Box>
        {toggle && (
          <Box
            mb="3px"
            mt="4px"
            display={["none", "none", "none", "block"]}
            pb="10px"
            pt={"10px"}
            fontSize="14px"
            width="100%"
          >
            <Link to={UserRoutes.portal.viewEvents}>View Events</Link>
          </Box>
        )}
      </HStack>
      <HStack
        pl={"10px"}
        spacing="10px"
        bg={activeLink(UserRoutes.portal.vendors).bgColor}
        color={activeLink(UserRoutes.portal.vendors).textColor}
        mb="15px"
        cursor={"pointer"}
        fontWeight={"500"}
        borderRadius={"0px 24px 24px 0px;"}
        onClick={() => {
          router(UserRoutes.portal.vendors);
        }}
        boxShadow={activeLink(UserRoutes.portal.vendors).boxShadow}
      >
        <Box as="span" pb="3px">
          <PiCalendarPlus
            fontSize={"30px"}
            color={activeLink(UserRoutes.portal.vendors).iconColour}
          />
        </Box>
        {toggle && (
          <Box
            mb="3px"
            mt="4px"
            display={["none", "none", "none", "block"]}
            pb="10px"
            pt={"10px"}
            fontSize="14px"
            width="100%"
          >
            <Link to={UserRoutes.portal.vendors}>Vendors</Link>
          </Box>
        )}
      </HStack>
      <HStack
        pl={"10px"}
        spacing="10px"
        borderRadius={"0px 24px 24px 0px;"}
        bg={activeLink(UserRoutes.portal.editEvent).bgColor}
        color={activeLink(UserRoutes.portal.editEvent).textColor}
        mb="15px"
        fontWeight={"500"}
        cursor={"pointer"}
        onClick={() => {
          router(UserRoutes.portal.editEvent);
        }}
        boxShadow={activeLink(UserRoutes.portal.editEvent).boxShadow}
      >
        <Box as="span" pb="3px">
          <BsChatRightDots
            fontSize={"30px"}
            color={activeLink(UserRoutes.portal.editEvent).iconColour}
          />
        </Box>
        {toggle && (
          <Box
            mb="3px"
            mt="4px"
            display={["none", "none", "none", "block"]}
            pb="10px"
            pt={"10px"}
            fontSize="14px"
            width="100%"
          >
            <Link to={UserRoutes.portal.editEvent}>Chat</Link>
          </Box>
        )}
      </HStack>

      <Box position={"absolute"} bottom={"0"}>
        <HStack
          cursor={"pointer"}
          bg={activeLink(UserRoutes.portal.home).bgColor}
          color={activeLink(UserRoutes.portal.home).textColor}
          pl={"10px"}
          spacing="10px"
          mb="15px"
          fontWeight={"500"}
        >
          <Box as="span" pb="3px">
            <HiOutlineLogout
              color={activeLink(UserRoutes.portal.home).iconColour}
              fontSize={"30px"}
            />
          </Box>
          {toggle && (
            <Box
              mb="3px"
              mt="4px"
              pb="10px"
              pt={"10px"}
              fontSize="14px"
              display={["none", "none", "none", "block"]}
              width="100%"
              onClick={() => {
                handleLogout();
              }}
            >
              <Text>Logout</Text>
            </Box>
          )}
        </HStack>
      </Box>
    </Box>
  );
};

export default AdminSidebar;

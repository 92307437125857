import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
  Button,
  HStack,
  Textarea,
  Stack,
  Select,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import event_activitiesService from "../../services/events/event_activities.service";
import commonService from "../../services/common/common.service";
import { IEventActivitiesData } from "../../models/events/event_activities.model";

export const EditEditEventActivityModal: React.FC<any> = ({
  isOpenEditEventActivity,
  onClosed,
  isEditEventActivity = false,

  activity,
  reload,
}: {
  isEditEventActivity: boolean;
  isOpenEditEventActivity: any;
  onClosed: any;

  activity: IEventActivitiesData;
  reload: any;
}) => {
  const toast = useToast();
  const cancelRef = useRef().current;
  const [currencyList, setcurrencyList] = useState<any[]>();
  const [value, setValue] = useState({
    displayValue: "0",
    actualNumberValue: 0,
  });
  useEffect(() => {
    formik.setValues({
      name: activity.name,
      currency: activity.currency.id,
      description: activity.description,
      start_date: activity.start_date,
      end_date: activity.end_date,
    });
    setValue({
      displayValue: activity.budget_amount.toString(),
      actualNumberValue: activity.budget_amount,
    });
    commonService
      .getCurrencies()
      .then((data) => {
        setcurrencyList(data.data);
      })
      .catch((err) => err);
  }, []);
  const formik = useFormik({
    validate: (values: any) => {
      const errors: any = {};
      if (!values.name) {
        errors.name = "name is required";
      }
      if (!values.currency) {
        errors.currency = "currency is required";
      }

      return errors;
    },
    initialValues: {
      name: "",
      currency: "",
      description: "",
      start_date: "",
      end_date: "",
    },
    onSubmit: async (values) => {
      try {
        const new_activity: any = {
          name: values.name,
          currency_id: values.currency,
          budget_amount: parseInt(value.actualNumberValue.toString()),
          description: values.description,
          start_date: values.start_date,
          end_date: values.end_date,
          status: "todo",
        };
        const edit_activity: any =
          await event_activitiesService.updateEventActivity(
            new_activity,
            activity.id
          );
        if (edit_activity.success) {
          toast({
            title: "Event Activity",
            description: `${edit_activity.message}`,
            status: "success",
          });
          onClosed(!isEditEventActivity);
          reload();
        } else {
          toast({
            title: "Event Activity",
            description: `${edit_activity.message}`,
            status: "error",
          });
        }
      } catch (error: any) {
        toast({
          title: "Event Activity",
          description: `${error.response.data.message}`,
          status: "error",
        });
      }
    },
  });
  const handleChange = (event: any) => {
    const strNumber = event.target.value
      .replace(/[^\d.]/g, "") // Remove non-numeric and non-decimal point characters
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"); // Add thousand separators

    const parts = strNumber.split(".");
    const integerPart = parts[0] || "0";
    const decimalPart = parts[1] || "00";

    // Ensure two decimal places
    const formattedNumber = `${integerPart}.${decimalPart.slice(0, 2)}`;

    setValue({
      displayValue: formattedNumber,
      actualNumberValue: parseFloat(formattedNumber.replace(/,/g, "")),
    });
  };
  return (
    <>
      <Modal
        isOpen={isOpenEditEventActivity && isEditEventActivity}
        onClose={onClosed}
        scrollBehavior="outside"
        closeOnOverlayClick={true}
        size={["sm", "sm", "md", "md"]}
      >
        <ModalOverlay />
        <ModalContent borderRadius={5} px={"5px"}>
          <ModalHeader>
            {
              <Text fontSize={"18px"} pb={1} textTransform="capitalize">
                Add Event Activity
              </Text>
            }
          </ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody pb={"30px"}>
            <Box mt={2}>
              <form onSubmit={formik.handleSubmit}>
                <FormControl
                  mb={"15px"}
                  isInvalid={
                    formik.errors.name && formik.touched.name ? true : false
                  }
                >
                  <FormLabel
                    fontWeight="normal"
                    color="black"
                    fontSize={"12px"}
                  >
                    Activity Name
                  </FormLabel>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    value={formik.values.name}
                    placeholder="Enter name"
                    onChange={formik.handleChange}
                    fontSize={"12px"}
                  />
                  <FormErrorMessage fontSize={"12px"}>
                    {formik.errors.name}
                  </FormErrorMessage>
                </FormControl>
                <Stack
                  mb={"15px"}
                  direction={["row", "row"]}
                  spacing={["15px", "15px", "15px", "15px"]}
                >
                  <FormControl>
                    <FormLabel color="#000000" fontSize={"12px"}>
                      Budget For Activity
                    </FormLabel>
                    <Input
                      type="text"
                      id="amount"
                      name="amount"
                      placeholder="Enter amount"
                      onBlur={handleChange}
                      value={value.displayValue}
                      onChange={(e) =>
                        setValue({ ...value, displayValue: e.target.value })
                      }
                      fontSize={"12px"}
                    />
                  </FormControl>
                  <FormControl mb="15px">
                    <FormLabel color="#000000" fontSize={"12px"}>
                      Currency
                    </FormLabel>

                    <Select
                      variant="outline"
                      size="md"
                      borderRadius={"10px"}
                      placeholder="select currency"
                      value={formik.values.currency}
                      name="currency"
                      bg={"#E9E9E9"}
                      onChange={formik.handleChange}
                      fontSize={"12px"}
                    >
                      {currencyList &&
                        currencyList.map((each, index) => (
                          <option key={index} value={each.id}>
                            {" "}
                            {`${each.code}`}
                          </option>
                        ))}
                    </Select>
                    <FormErrorMessage fontSize={12}>
                      {formik.errors.currency}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
                <Stack
                  mb={"15px"}
                  direction={["row", "row"]}
                  spacing={["15px", "15px", "15px", "15px"]}
                >
                  <FormControl
                    mb="0px"
                    isInvalid={
                      formik.errors.start_date && formik.touched.start_date
                        ? true
                        : false
                    }
                  >
                    <FormLabel color="#000000" fontSize={"12px"}>
                      Start date
                    </FormLabel>

                    <Input
                      type="date"
                      id="start_date"
                      size="sm"
                      name="start_date"
                      placeholder=""
                      value={formik.values.start_date}
                      onChange={formik.handleChange}
                      fontSize={"12px"}
                    />

                    <FormErrorMessage fontSize={12}>
                      {formik.errors.start_date}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    mb="0px"
                    isInvalid={
                      formik.errors.start_date && formik.touched.start_date
                        ? true
                        : false
                    }
                  >
                    <FormLabel color="#000000" fontSize={"12px"}>
                      Start date
                    </FormLabel>

                    <Input
                      type="date"
                      id="end_date"
                      size="sm"
                      name="end_date"
                      placeholder=""
                      value={formik.values.end_date}
                      onChange={formik.handleChange}
                      fontSize={"12px"}
                    />

                    <FormErrorMessage fontSize={12}>
                      {formik.errors.end_date}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
                <FormControl
                  mb="15px"
                  isInvalid={
                    formik.errors.description && formik.touched.description
                      ? true
                      : false
                  }
                >
                  <FormLabel
                    fontWeight="normal"
                    color="#000000"
                    fontSize={"12px"}
                  >
                    Description
                  </FormLabel>
                  <Textarea
                    p={4}
                    fontSize={"14px"}
                    size="sm"
                    minH="50px"
                    placeholder=" Description"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.name}
                  </FormErrorMessage>
                </FormControl>

                <Flex mt={"10px"} justifyContent={"space-between"}>
                  <Button
                    px={6}
                    colorScheme="purple"
                    fontSize={"14px"}
                    variant={"outline"}
                    ref={cancelRef}
                    width={"49%"}
                    onClick={() => onClosed()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isLoading={formik.isSubmitting}
                    color={"white"}
                    width={"49%"}
                    bg={"#583A76"}
                    px={6}
                    fontSize={"14px"}
                  >
                    Confirm
                  </Button>
                </Flex>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

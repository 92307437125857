import {
  Avatar,
  Flex,
  HStack,
  Text,
  Image,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Center,
  Portal,
  Link,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { BiNotification } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { destroyUserSession } from "../../services/auth/auth.service";
import UserRoutes from "../../pages/routes";
import { FiMaximize, FiMinimize } from "react-icons/fi";
import { BiMenuAltLeft } from "react-icons/bi";
import logo from "../../img/partilogo.png";
import { VscBellDot } from "react-icons/vsc";
import { IoIosSearch } from "react-icons/io";
const AdminDashboardHeader = ({
  toggle,
  toggleFunct,
}: {
  toggle: boolean;
  toggleFunct: any;
}) => {
  const [user, setUserProfile] = useState<any>();

  const router = useNavigate();
  let location = useLocation();

  useEffect(() => {}, []);

  return (
    <Box>
      <Box>
        <Flex
          alignItems="center"
          mt="5px"
          px={"20px"}
          display={["flex", "flex", "none", "none"]}
          justifyContent={"space-between"}
          boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.10);"}
        >
          <Link as="span">
            <Image src={logo} height="59px" width={"38px"} />
          </Link>
          <Center>
            <BiMenuAltLeft fontSize={"35px"} cursor={"pointer"} />
          </Center>
        </Flex>
        <Flex
          color="#616161"
          direction="row"
          mt="10px"
          alignItems="center"
          justifyContent="space-between"
          px={"20px"}
          borderBottom={[
            "none",
            "none",
            "2px solid #D9D9D980",
            "2px solid #D9D9D980",
          ]}
          py={["0px", "0px", "10px", "10px"]}
        >
          {/* {toggle ? (
            <IconButton
              variant="ghost"
              mr={"20px"}
              aria-label="bell"
              borderRadius={20}
              onClick={() => toggleFunct()}
              icon={<FiMaximize fontSize={"18px"} color="#898989" />}
            />
          ) : (
            <IconButton
              variant="ghost"
              mr={"20px"}
              aria-label="bell"
              borderRadius={20}
              onClick={() => toggleFunct()}
              icon={<FiMinimize fontSize={"18px"} color="#898989" />}
            />
          )} */}
          <FormControl>
            <InputGroup>
              <InputLeftElement
                color="brand.subtitle"
                pointerEvents="none"
                children={<IoIosSearch />}
              />
              <Input
                w={["180px", "200px", "300px", "400px"]}
                type="text"
                id="search"
                name="search"
                placeholder="search"
                fontSize={14}
                bg={["#E0E0E0", "#E0E0E0", "white", "white"]}
                color={"black"}
              />
            </InputGroup>
          </FormControl>
          <HStack spacing={["15px", "15px", "40px", "40px"]}>
            <VscBellDot fontSize={"24px"} />
            <Avatar size="sm" name={`Covenant Bolaji`} />
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
};

export default AdminDashboardHeader;

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  FormLabel,
  Input,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { useFormik } from "formik";

import { useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import UserRoutes from "../routes";
import authService, { getAuthToken } from "../../services/auth/auth.service";
import { useUserContext } from "../../components/common/UserContext";

const SetPassword = () => {
  const route = useNavigate();
  const toast = useToast();
  const { data } = useUserContext();
  const validate = (values: any) => {
    const errors: any = {};

    if (!values.password) {
      errors.password = "Password is required";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password";
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password do not match";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: { confirmPassword: "", password: "", otp: "" },
    validate,
    onSubmit: async (values) => {
      try {
        const dataBody = {
          email: data.email,
          password: values.password,
          password_confirmation: values.confirmPassword,
          otp: values.otp,
        };
        const reset_password: any = await authService.resetPassword(dataBody);
        if (reset_password.message) {
          toast({
            title: "Reset Password",
            description: `Password reset successful`,
            status: "success",
          });
          route(UserRoutes.Login);
        } else {
          toast({
            title: "Reset Password",
            description: `${reset_password.message}`,
            status: "error",
          });
        }
      } catch (error: any) {
        toast({
          title: "Reset Password",
          description: `${error.response.data.message}`,
          status: "error",
        });
      }
    
    },
  });

  return (
    <>
      <AuthLayout>
        <Flex justifyContent={"center"} pt={"60px"} height="100vh">
          <Box>
            <Box>
              <Box  display={['none','none', 'block','block']} mb={"40px"}>
                <Text
                  mb={"10px"}
                  fontSize={["20px", "20px", "24px", "24px"]}
                  fontWeight="700"
                  color="#000000"
                  textAlign={"center"}
                >
                  Reset Password
                </Text>
                <Text
                  fontSize={["10px", "10px", "14px", "14px"]}
                  fontWeight="400"
                  color="#90979E"
                  textAlign={"center"}
                >
                  Reset your password to access your account
                </Text>
              </Box>

              <form onSubmit={formik.handleSubmit}>
                <FormControl
                  mb="20px"
                  isInvalid={
                    formik.errors.password && formik.touched.password
                      ? true
                      : false
                  }
                >
                  <FormLabel fontSize={15}>OTP </FormLabel>

                  <Input
                    w={["300px", "300px", "400px", "400px"]}
                    type="numer"
                    name="otp"
                    id="otp"
                    placeholder="Enter  Otp"
                    onChange={formik.handleChange}
                    fontSize={14}
                  />

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.otp}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  mb="20px"
                  isInvalid={
                    formik.errors.password && formik.touched.password
                      ? true
                      : false
                  }
                >
                  <FormLabel fontSize={15}>New password </FormLabel>

                  <Input
                    w={["300px", "300px", "400px", "400px"]}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter  password"
                    onChange={formik.handleChange}
                    fontSize={14}
                  />

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.password}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  mb="20px"
                  isInvalid={
                    formik.errors.confirmPassword &&
                    formik.touched.confirmPassword
                      ? true
                      : false
                  }
                >
                  <FormLabel fontSize={15}>Confirm new password </FormLabel>

                  <Input
                    w={["300px", "300px", "400px", "400px"]}
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirm password"
                    onChange={formik.handleChange}
                    fontSize={14}
                  />

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.confirmPassword}
                  </FormErrorMessage>
                </FormControl>

                <Flex justifyContent={"flex-end"}>
                  <Button
                    cursor="pointer"
                    color={"white"}
                    bg={"#583A76"}
                    type="submit"
                    fontWeight={"400"}
                    isLoading={formik.isSubmitting}
                    fontSize={"14px"}
                    size={"sm"}
                  >
                    Reset password
                  </Button>
                </Flex>
              </form>
            </Box>
          </Box>
        </Flex>
      </AuthLayout>
    </>
  );
};

export default SetPassword;

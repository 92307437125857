import {
  Flex,
  Image,
  HStack,
  Box,
  Text,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Input,
  Center,
  Link,
} from "@chakra-ui/react";

import congrats from "../../img/msg.svg";

import { useState } from "react";
import authService from "../../services/auth/auth.service";
import { useNavigate } from "react-router-dom";
import OtpInputWithValidation from "../../components/OtpInput";
import UserRoutes from "../routes";
import AuthLayout from "./AuthLayout";
import { useUserContext } from "../../components/common/UserContext";

const EmailSent = () => {
  const toast = useToast();
  const route = useNavigate();
  const { data } = useUserContext();
const [isSubmitting , setissubmitting] = useState<boolean>(false)
  const [valueOtp, setValueOtp] = useState<string>();

  const handleSubmit = async () => {
    setissubmitting(true)
    const dataBody = {
      email: data.email,
      otp: valueOtp,
    };
    try {
      const verify_otp = await authService.verifyOTP(dataBody);
      if (verify_otp.status) {
       
        const verify_user = await authService.verify(dataBody);
        if (verify_user.token) {
          setissubmitting(false)
          route(UserRoutes.emailVerified);
          toast({
            title: "Verification",
            description: `${verify_user.message}`,
            status: "success",
          });
        }
      }else{
        setissubmitting(false)
        toast({
          title: "Verification",
          description: `${verify_otp.message}`,
          status: "error",
        });
      }
    } catch (error: any) {
      setissubmitting(false)
      toast({
        title: "Register",
        description: `${error.response.data.message}`,
        status: "error",
      });
    }
  };
  const getOtpValue = (value: string) => {
    setValueOtp(value);
  };
  return (
    <>
      <AuthLayout>
        <Box>
          <Flex
            height={"100vh"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
          >
            <Box width={"365px"}>
              <Text
                mb={"5px"}
                fontSize={["20px", "20px", "24px", "24px"]}
                fontWeight="700"
                color="#353535"
                textAlign={"center"}
              >
                Almost Done!
              </Text>
              <Flex justifyContent={"center"}>
                <Image src={congrats} />
              </Flex>
              <Box mt={"10px"} mb={"20px"}>
                <Text
                  fontSize={["10px", "10px", "12px", "18px"]}
                  fontWeight="400"
                  mb={"20px"}
                  color="#000000"
                  textAlign={"center"}
                >
                  We just emailed you.
                </Text>
                <Text
                  fontSize={["10px", "10px", "12px", "12px"]}
                  fontWeight="400"
                  mb={"20px"}
                  color="#90979E"
                  textAlign={"center"}
                >
                  Please enter the code we emailed you.
                </Text>
                <Text
                  fontSize={["10px", "10px", "12px", "12px"]}
                  fontWeight="400"
                  mb={"30px"}
                  color="#000000"
                  textAlign={"center"}
                >
                  Confirmation code
                </Text>
                <Box mb={"20px"}>
                  <OtpInputWithValidation
                    numberOfDigits={4}
                    valueOtp={getOtpValue}
                  />
                  <Flex justifyContent={"center"} mt={"20px"}>
                    <Button
                      type="button"
                      color={"white"}
                      bg={"#583A76"}
                      px={6}
                      size={"md"}
                      onClick={() => {
                        handleSubmit();
                      }}
                      fontSize={"14px"}
                    >
                      Verify
                    </Button>
                  </Flex>
                  <Flex mt={"30px"} justifyContent={"center"}>
                    <Center>
                      <Box
                        mb={2}
                        fontWeight={"600"}
                        alignSelf="center"
                        color="#000000"
                        fontSize={14}
                      >
                        <Link
                          onClick={() => {
                            route(UserRoutes.forgotPassword);
                          }}
                        >
                          Resend code
                        </Link>
                      </Box>
                    </Center>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      </AuthLayout>
    </>
  );
};

export default EmailSent;

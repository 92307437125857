// DataContext.js
import React, { createContext, useContext, useState } from 'react';
import { ILoginUser } from '../../models/auth/login.model';


// Define the initial context values
const initialContext: { data: ILoginUser; onDataChange: (newData: ILoginUser) => void } = {
  data: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    email_verified_at: '',
    gender: '',
    username: '',
    address: '',
    phone_number: '',
    avatar: '',
    is_active: false,
    vendor_id: '',
    deleted_at: '',
    created_at: '',
    updated_at: '',
    role: '',
    token : ''
  },
  onDataChange: (newData: ILoginUser) => { },
};

const UserContext = createContext(
  initialContext
);

export const UserProvider = ({ children } : {children : any}) => {
  const [data, setData] = useState<ILoginUser>(initialContext.data);

  const onDataChange = (newData: ILoginUser) => {
    setData(newData);
  };
  const contextValue = {
    ...initialContext,
    data,
    onDataChange,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};

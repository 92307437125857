import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Link,
  HStack,
  Image,
  Stack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { MdLock, MdMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { useEffect, useRef, useState } from "react";
import UserRoutes from "../routes";
import authService from "../../services/auth/auth.service";
import { useUserContext } from "../../components/common/UserContext";

const ForgotPassword = () => {
  const route = useNavigate();
  const { onDataChange } = useUserContext();
  const toast = useToast();

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validate,
    onSubmit: async (values) => {
      try {
        const data = {
          email: values.email,
        };
        const forgot_password: any = await authService.forgot_password(data);
        console.log(forgot_password.message);
        if (forgot_password.message) {
          onDataChange({
            email: values.email,
            id: "",
            first_name: "",
            last_name: "",
            email_verified_at: undefined,
            gender: undefined,
            username: undefined,
            address: "",
            phone_number: "",
            avatar: undefined,
            is_active: false,
            vendor_id: undefined,
            deleted_at: undefined,
            created_at: "",
            updated_at: "",
            role: "",
          });
          toast({
            title: "Forgot Password",
            description: `Email sent. Kindy check your email`,
            status: "success",
          });
          route(UserRoutes.setPassword);
        } else {
          toast({
            title: "Forgot Password",
            description: `${forgot_password.message}`,
            status: "error",
          });
        }
      } catch (error: any) {
        toast({
          title: "Forgot Password",
          description: `${error.response.data.message}`,
          status: "error",
        });
      }
    },
  });

  return (
    <>
      <AuthLayout>
        <Flex justifyContent={"center"} pt={'60px'}  height="100vh">
          <Box>
            <Box>
              <Box display={['none','none', 'block','block']} mb={"40px"}>
                <Text
                  mb={"10px"}
                  fontSize={["20px", "20px", "24px", "24px"]}
                  fontWeight="700"
                  color="#000000"
                  textAlign={"center"}
                >
                  Forgot Password
                </Text>
                <Text
                  fontSize={["10px", "10px", "13px", "13px"]}
                  fontWeight="400"
                  color="#90979E"
                  textAlign={"center"}
                  mb={'100px'}
                >
                  Enter email to reset password
                </Text>
              </Box>

              <form onSubmit={formik.handleSubmit}>
                <FormControl
                  mb="20px"
                  isInvalid={
                    formik.errors.email && formik.touched.email ? true : false
                  }
                >
                  <FormLabel color="#757575" fontSize={15}>
                    Email
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      color="brand.subtitle"
                      pointerEvents="none"
                      children={<MdMail />}
                    />
                    <Input
                      w={["300px", "300px", "400px", "400px"]}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter email"
                      onChange={formik.handleChange}
                      fontSize={14}
                    />
                  </InputGroup>

                  <FormErrorMessage fontSize={12}>
                    {formik.errors.email}
                  </FormErrorMessage>
                </FormControl>

                <Stack spacing="12px">
                  <Flex justifyContent={"flex-end"}>
                    <Button
                      cursor="pointer"
                      color={"white"}
                      bg={"#583A76"}
                      type="submit"
                      fontWeight={"400"}
                      isLoading={formik.isSubmitting}
                      fontSize={"14px"}
                      size={"sm"}
                    >
                      Confirm
                    </Button>
                  </Flex>
                </Stack>
              </form>
            </Box>
          </Box>
        </Flex>
      </AuthLayout>
    </>
  );
};

export default ForgotPassword;

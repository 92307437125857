import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Link,
  HStack,
  Image,
  Stack,
  InputRightElement,
  Portal,
  WrapItem,
  Wrap,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { MdLock } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import UserRoutes from "../routes";
import authService from "../../services/auth/auth.service";
import { IoMailOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import OtpInputWithValidation from "../../components/OtpInput";
import commonService from "../../services/common/common.service";
import {
  ICategoriesData,
  ICategoriesRes,
} from "../../models/categories/categories.model";
import { IServiceData } from "../../models/services/service.model";
import { useUserContext } from "../../components/common/UserContext";
import { BsDot } from "react-icons/bs";
const Register = () => {
  const router = useNavigate();
  const toast = useToast();
  const { onDataChange } = useUserContext();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [selectCategory, setSelectedCategory] = useState<string>("user");
  const [vendorRegStage, setVendorRegStage] = useState<number>(1);
  const [selectedCategoriesId, setselectedCategoriesId] = useState<string[]>(
    []
  );
  const [selectedServiceId, setselectedServiceId] = useState<string[]>([]);
  const [categoriesList, setCategoriesList] = useState<ICategoriesData[]>();
  const [serviceList, setServiceList] = useState<IServiceData[]>();
  console.log(selectedServiceId, 'categories list')
  console.log(selectedCategoriesId, 'categories list')
  useEffect(() => {
    commonService
      .getCatgories()
      .then((data) => {
        setCategoriesList(data.data);
      })
      .catch((err) => err);
    commonService
      .getServices()
      .then((data) => {
        setServiceList(data.data);
      })
      .catch((err) => err);
  }, []);

  const handleCategorySelection = (categoryId: string) => {
    const isSelected = selectedCategoriesId.includes(categoryId);

    if (isSelected) {
      setselectedCategoriesId(
        selectedCategoriesId.filter((selectedId) => selectedId !== categoryId)
      );
    } else {
      setselectedCategoriesId([...selectedCategoriesId, categoryId]);
    }
  };
  const handleServiceSelection = (serviceId: string) => {
    const isSelected = selectedServiceId.includes(serviceId);

    if (isSelected) {
      setselectedServiceId(
        selectedServiceId.filter((selectedId) => selectedId !== serviceId)
      );
    } else {
      setselectedServiceId([...selectedServiceId, serviceId]);
    }
  };

  const prevPage = () => {
    if (vendorRegStage < 1) {
      setVendorRegStage(1);
    } else {
      setVendorRegStage((prev) => prev - 1);
    }
  };
  const nextPage = () => {
    if (vendorRegStage < 3) {
      setVendorRegStage((prev) => prev + 1);
    }
  };
  const switchToUser = () => {
    setVendorRegStage(1);
    setSelectedCategory("user");
  };
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.username) {
      errors.username = "username is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      firstname: "",
      lastname: "",
      confirmp: "",
      phone: "",
      address: "",
      company: "",
    },
    validate,
    onSubmit: async (values) => {
      try {
        const data = {
          user_type: "USER",
          first_name: values.firstname,
          last_name: values.lastname,
          phone_number: values.phone,
          address: values.address,
          email: values.username,
          password: values.password,
          password_confirmation: values.confirmp,
        };
        const dataVendor = {
          user_type: "VENDOR",
          business_name: values.company,
          first_name: values.firstname,
          last_name: values.lastname,
          phone_number: values.phone,
          address: values.address,
          email: values.username,
          password: values.password,
          password_confirmation: values.confirmp,
          categories: [...selectedCategoriesId],
          services: [...selectedServiceId],
        };
        if (selectCategory == "user") {
          const register_user = await authService.register(data);

          if (register_user.message) {
            router(UserRoutes.emailSent);
            onDataChange({
              email: values.username,
              id: "",
              first_name: "",
              last_name: "",
              email_verified_at: undefined,
              gender: undefined,
              username: undefined,
              address: "",
              phone_number: "",
              avatar: undefined,
              is_active: false,
              vendor_id: undefined,
              deleted_at: undefined,
              created_at: "",
              updated_at: "",
              role: "",
            });
            toast({
              title: "Register",
              description: `${register_user.message}`,
              status: "success",
            });
          } else {
            toast({
              title: "Register",
              description: `${register_user.message}`,
              status: "success",
            });
          }
        } else {
          const register_user = await authService.register(dataVendor);
          if (register_user.message) {
            router(UserRoutes.emailSent);
            onDataChange({
              email: values.username,
              id: "",
              first_name: "",
              last_name: "",
              email_verified_at: undefined,
              gender: undefined,
              username: undefined,
              address: "",
              phone_number: "",
              avatar: undefined,
              is_active: false,
              vendor_id: undefined,
              deleted_at: undefined,
              created_at: "",
              updated_at: "",
              role: "",
            });
            toast({
              title: "Register",
              description: `Registration successful`,
              status: "success",
            });
          }
        }
      } catch (error: any) {
        console.log(error, "error");
        toast({
          title: "Register",
          description: `${error.response.data.message}`,
          status: "error",
        });
      }
    },
  });

  return (
    <>
      <AuthLayout>
        <Box>

          <Flex
            justifyContent={'flex-start'} ml={["0px", "0px", "30px", "30px"]}
            mt={["30px", "30px", "30px", "30px"]}
            mb={["30px", "30px", "50px", "50px"]}
            borderBottom={[
              "2px solid #583A76",
              "2px solid #583A76",
              "none",
              "none",
            ]}

          >
            <Text
              ml={["20px", "20px", "0px", "0px"]}
              border={selectCategory == "user" ? "1px solid" : "1px solid"}
              borderColor={"#583A76"}
              color={selectCategory == "user" ? "white" : "#583A76"}
              bg={selectCategory == "user" ? "#583A76" : "white"}
              py={"10px"}
              px={"16px"}
              cursor={"pointer"}
              onClick={() => {
                switchToUser();
              }}
              mr={["2px", "2px", "2px", "2px"]}
              borderRadius={[
                "8px 8px 0px 0px",
                "8px 8px 0px 0px",
                "4px",
                "4px",
              ]}
            >
              as a User
            </Text>
            <Text
              borderRadius={[
                "8px 8px 0px 0px",
                "8px 8px 0px 0px",
                "4px",
                "4px",
              ]}
              border={selectCategory == "vendor" ? "1px solid" : "1px solid"}
              borderColor={"#583A76"}
              color={selectCategory == "vendor" ? "white" : "#583A76"}
              bg={selectCategory == "vendor" ? "#583A76" : "white"}
              py={"10px"}
              px={"16px"}
              cursor={"pointer"}
              onClick={() => {
                setSelectedCategory("vendor");
              }}
            >
              as a Vendor
            </Text>

          </Flex>

          {selectCategory == "vendor" && (
            <Flex mb={"30px"} justifyContent={"center"}>
              <HStack
                display={["none", "none", "inherit", "inherit"]}
                spacing={"10px"}
              >
                <Center
                  onClick={() => {
                    setVendorRegStage(1);
                  }}
                  bg={vendorRegStage >= 1 ? "#583A76" : "white"}
                  border={
                    vendorRegStage >= 1
                      ? "2px solid #583A76"
                      : "2px solid #D1D1D1"
                  }
                  width={"36px"}
                  height={"36px"}
                  borderRadius={"18px"}
                >
                  <Text color={vendorRegStage == 1 ? "white" : "#D1D1D1"}>
                    1
                  </Text>
                </Center>

                <Box
                  width={"60px"}
                  height={"15px"}
                  borderRadius={"16px"}
                  bg={vendorRegStage > 1 ? "#583A76" : "white"}
                  border={
                    vendorRegStage > 1
                      ? "2px solid #583A76"
                      : "2px solid #D1D1D1"
                  }
                ></Box>
                <Center
                  onClick={() => {
                    setVendorRegStage(2);
                  }}
                  bg={vendorRegStage >= 2 ? "#583A76" : "white"}
                  border={
                    vendorRegStage >= 2
                      ? "2px solid #583A76"
                      : "2px solid #D1D1D1"
                  }
                  width={"36px"}
                  height={"36px"}
                  borderRadius={"18px"}
                >
                  <Text color={vendorRegStage >= 2 ? "white" : "#D1D1D1"}>
                    2
                  </Text>
                </Center>
                <Box
                  width={"60px"}
                  height={"15px"}
                  borderRadius={"16px"}
                  bg={vendorRegStage == 3 ? "#583A76" : "white"}
                  border={
                    vendorRegStage == 3
                      ? "2px solid #583A76"
                      : "2px solid #D1D1D1"
                  }
                ></Box>
                <Center
                  bg={vendorRegStage == 3 ? "#583A76" : "white"}
                  onClick={() => {
                    setVendorRegStage(3);
                  }}
                  border={
                    vendorRegStage == 3
                      ? "2px solid #583A76"
                      : "2px solid #D1D1D1"
                  }
                  width={"36px"}
                  height={"36px"}
                  borderRadius={"18px"}
                >
                  <Text color={vendorRegStage == 3 ? "white" : "#D1D1D1"}>
                    3
                  </Text>
                </Center>
              </HStack>
              <HStack
                display={["inherit", "inherit", "none", "none"]}
                spacing={"3px"}
              >

                <Box
                  width={vendorRegStage == 1 ? "20px" : "6px"}
                  height={"6px"}
                  borderRadius={"3px"}
                  bg={vendorRegStage == 1 ? "#583A76" : "#D9D9D9"}
                  border={
                    vendorRegStage == 1
                      ? "2px solid #583A76"
                      : 'none'
                  }
                ></Box>
                <Box
                  width={vendorRegStage == 2 ? "20px" : "6px"}
                  height={"6px"}
                  borderRadius={"3px"}
                  bg={vendorRegStage == 2 ? "#583A76" : "#D9D9D9"}
                  border={
                    vendorRegStage == 2
                      ? "2px solid #583A76"
                      : 'none'
                  }
                ></Box>
                <Box
                  width={vendorRegStage == 3 ? "20px" : "6px"}
                  height={"6px"}
                  borderRadius={"3px"}
                  bg={vendorRegStage == 3 ? "#583A76" : "#D9D9D9"}
                  border={
                    vendorRegStage == 3
                      ? "2px solid #583A76"
                      : 'none'
                  }
                ></Box>

              </HStack>
            </Flex>
          )}
          <Flex justifyContent={"center"} height="99vh">
            <Box>
              <Box mb="15px">
                <form onSubmit={formik.handleSubmit}>
                  {vendorRegStage == 1 && (
                    <Box px={'20px'} >
                      {selectCategory == "vendor" && (
                        <FormControl mb="10px">
                          <FormLabel color="brand.subtitle" fontSize={"13px"}>
                            Company name
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<IoMailOutline />}
                            />
                            <Input
                              w={["300px", "300px", "400px", "400px"]}
                              type="text"
                              name="company"
                              id="company"
                              placeholder="Enter company name"
                              onChange={formik.handleChange}
                              fontSize={"13px"}
                            />
                          </InputGroup>
                          <FormErrorMessage fontSize={12}>
                            {formik.errors.company}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                      <FormControl mb="10px">
                        <FormLabel color="brand.subtitle" fontSize={"13px"}>
                          Firstname
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<IoMailOutline />}
                          />
                          <Input
                            w={["300px", "300px", "400px", "400px"]}
                            type="text"
                            name="firstname"
                            id="firstname"
                            placeholder="Enter Firstname"
                            onChange={formik.handleChange}
                            fontSize={"13px"}
                          />
                        </InputGroup>
                        <FormErrorMessage fontSize={12}>
                          {formik.errors.firstname}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl mb="10px">
                        <FormLabel color="brand.subtitle" fontSize={"13px"}>
                          Lastname
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<IoMailOutline />}
                          />
                          <Input
                            w={["300px", "300px", "400px", "400px"]}
                            type="text"
                            name="lastname"
                            id="lastname"
                            placeholder="Enter Lastname"
                            onChange={formik.handleChange}
                            fontSize={"13px"}
                          />
                        </InputGroup>
                        <FormErrorMessage fontSize={12}>
                          {formik.errors.lastname}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        mb="10px"
                        isInvalid={
                          formik.errors.username && formik.touched.username
                            ? true
                            : false
                        }
                      >
                        <FormLabel color="#757575" fontSize={"13px"}>
                          Email
                        </FormLabel>

                        <Input
                          w={["300px", "300px", "400px", "400px"]}
                          type="email"
                          id="username"
                          name="username"
                          placeholder="Enter email"
                          onChange={formik.handleChange}
                          fontSize={"13px"}
                        />

                        <FormErrorMessage fontSize={12}>
                          {formik.errors.username}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        mb="10px"
                        isInvalid={
                          formik.errors.password && formik.touched.password
                            ? true
                            : false
                        }
                      >
                        <FormLabel color="brand.subtitle" fontSize={"13px"}>
                          Password{" "}
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            color="brand.subtitle"
                            pointerEvents="none"
                            children={<MdLock />}
                          />
                          <Input
                            w={["300px", "300px", "400px", "400px"]}
                            type={show ? "text" : "password"}
                            name="password"
                            id="password"
                            placeholder="Enter  password"
                            onChange={formik.handleChange}
                            fontSize={"13px"}
                          />
                          <InputRightElement width="4.5rem">
                            <Center
                              cursor={"pointer"}
                              h="1.75rem"
                              onClick={handleClick}
                            >
                              {show ? <BiHide /> : <BiShow />}
                            </Center>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage fontSize={12}>
                          {formik.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl mb="10px">
                        <FormLabel color="brand.subtitle" fontSize={"13px"}>
                          Confirm Password{" "}
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<MdLock />}
                          />
                          <Input
                            w={["300px", "300px", "400px", "400px"]}
                            type={show ? "text" : "password"}
                            name="confirmp"
                            id="confirmp"
                            placeholder="Confirm  password"
                            onChange={formik.handleChange}
                            fontSize={"13px"}
                          />
                          <InputRightElement width="4.5rem">
                            <Center
                              cursor={"pointer"}
                              h="1.75rem"
                              onClick={handleClick}
                            >
                              {show ? <BiHide /> : <BiShow />}
                            </Center>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage fontSize={12}>
                          {formik.errors.confirmp}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl mb="10px">
                        <FormLabel color="brand.subtitle" fontSize={"13px"}>
                          Mobile Number
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<IoMailOutline />}
                          />
                          <Input
                            w={["300px", "300px", "400px", "400px"]}
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Enter phone"
                            onChange={formik.handleChange}
                            fontSize={"13px"}
                          />
                        </InputGroup>
                        <FormErrorMessage fontSize={12}>
                          {formik.errors.phone}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl mb="10px">
                        <FormLabel color="brand.subtitle" fontSize={"13px"}>
                          Address
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<IoMailOutline />}
                          />
                          <Input
                            w={["300px", "300px", "400px", "400px"]}
                            type="text"
                            name="address"
                            id="address"
                            placeholder="Enter address"
                            onChange={formik.handleChange}
                            fontSize={"13px"}
                          />
                        </InputGroup>
                        <FormErrorMessage fontSize={12}>
                          {formik.errors.address}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  )}
                  {vendorRegStage == 2 && (
                    <Box>
                      <Flex mb={"20px"} justifyContent={"space-between"}>
                        <Text px={["20px", "20px", "0px", "0px"]} fontSize={"13px"}>
                          Choose atleast 1 option from the following event
                          planning categories.
                        </Text>
                      </Flex>
                      <Flex justifyContent={"center"}>
                      <Flex
                          px={["20px", "20px", "0px", "0px"]}
                          width={["100%", "100%", "400px", "450px"]}
                          flexDirection={['column', 'column', 'row', 'row']}
                          justifyContent={['center', 'center', 'flex-start', 'flex-start']}
                          wrap={['nowrap', 'nowrap', 'wrap', 'wrap']}
                          maxHeight={'400px'}
                          overflowY={'auto'}
                          overflowX={'hidden'}

                        >
                          {categoriesList &&
                            categoriesList.map((category, index) => (
                             
                              <Text
                              borderRadius={"8px"}
                              border={"1px solid"}
                              key={index}
                              bg={
                                selectedCategoriesId.includes(category.id)
                                  ? "#583A76"
                                  : "white"
                              }
                              color={
                                selectedCategoriesId.includes(category.id)
                                  ? "#FFFFFF"
                                  : "#475367"
                              }
                              borderColor={"#583A76"}
                              py={"6px"}
                              px={"12px"}
                              textAlign={'center'}
                              onClick={() => {
                                handleCategorySelection(category.id);
                              }}
                              cursor={"pointer"}
                              _hover={{
                                color: "white",
                                bg: "#583A76",
                              }}
                              textTransform={"capitalize"}
                              fontSize={["14px", "14px", "14px", "14px"]}
                              mr={['0px', '0px', '20px', '20px']}
                              mt={'15px'}
                            >
                              {category.name}
                            </Text>
                            
                            ))}
                        </Flex>
                      </Flex>
                    </Box>
                  )}
                  {vendorRegStage == 3 && (
                    <Box>
                      <Flex mb={"20px"} justifyContent={"space-between"}>
                        <Text px={["20px", "20px", "0px", "0px"]} fontSize={"13px"}>
                          Choose atleast 1 option from the following event
                          planning services you will be rendering.
                        </Text>
                      </Flex>
                      <Flex justifyContent={"center"}>
                        <Flex
                          px={["20px", "20px", "0px", "0px"]}
                          width={["100%", "100%", "400px", "450px"]}
                          flexDirection={['column', 'column', 'row', 'row']}
                          justifyContent={['center', 'center', 'flex-start', 'flex-start']}
                          wrap={['nowrap', 'nowrap', 'wrap', 'wrap']}
                          maxHeight={'400px'}
                          overflowY={'auto'}
                          overflowX={'hidden'}

                        >
                          {serviceList &&
                            serviceList.map((service, index) => (

                              <Text
                                borderRadius={"8px"}
                                border={"1px solid"}
                                key={index}
                                bg={
                                  selectedServiceId.includes(service.id)
                                    ? "#583A76"
                                    : "white"
                                }
                                color={
                                  selectedServiceId.includes(service.id)
                                    ? "#FFFFFF"
                                    : "#475367"
                                }
                                borderColor={"#583A76"}
                                py={"6px"}
                                px={"12px"}
                                textAlign={'center'}
                                onClick={() => {
                                  handleServiceSelection(service.id);
                                }}
                                cursor={"pointer"}
                                _hover={{
                                  color: "white",
                                  bg: "#583A76",
                                }}
                                textTransform={"capitalize"}
                                fontSize={["14px", "14px", "14px", "14px"]}
                                mr={['0px', '0px', '20px', '20px']}
                                mt={'15px'}
                              >
                                {service.name}
                              </Text>

                            ))}
                        </Flex>
                      </Flex>
                    </Box>
                  )}
                  <Flex
                    mt={"20px"}
                    pb={'50px'}
                    justifyContent={
                      selectCategory == "user"
                        ? "flex-end"
                        : vendorRegStage > 1 || vendorRegStage == 3
                          ? "space-between"
                          : "flex-end"
                    }
                    px={["20px", "20px", "0px", "0px"]}
                  >
                    {vendorRegStage > 1 && (
                      <Button
                        cursor="pointer"

                        colorScheme="purple"
                        variant={'outline'}
                        fontWeight={"400"}
                        fontSize={"13px"}
                        height={'48px'}
                        onClick={() => {
                          prevPage();
                        }}
                      >
                        Previous
                      </Button>
                    )}
                    {vendorRegStage < 3 && selectCategory !== "user" && (
                      <Button
                        isDisabled={(selectedCategoriesId.length == 0 && vendorRegStage == 2)}
                        cursor="pointer"
                        color={"white"}
                        bg={"#583A76"}
                        height={'48px'}
                        fontWeight={"400"}
                        fontSize={"13px"}
                        onClick={() => {
                          nextPage();
                        }}
                      >
                        Next
                      </Button>
                    )}
                    {(vendorRegStage == 3) && (
                      <Button
                        height={'48px'}
                        cursor="pointer"
                        color={"white"}
                        bg={"#583A76"}
                        type="submit"
                        fontWeight={"400"}
                        isLoading={formik.isSubmitting}
                        fontSize={"13px"}
                      >
                        Sign Up
                      </Button>
                    )}
                    {(selectCategory == "user") && (
                      <Button

                        cursor="pointer"
                        color={"white"}
                        bg={"#583A76"}
                        type="submit"
                        height={'48px'}
                        width={['100%', '100%', 'initial', 'inherit']}
                        fontWeight={"400"}
                        isLoading={formik.isSubmitting}
                        fontSize={"13px"}
                      >
                        Sign Up
                      </Button>
                    )}
                  </Flex>
                </form>
              </Box>
            </Box>
          </Flex>
        </Box>
      </AuthLayout>
    </>
  );
};

export default Register;

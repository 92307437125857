import React from 'react'
import { FaSearch } from 'react-icons/fa';

function ListHeader() {
  return (
    <div className="flex md:backdrop:px-5 lg:px-0 flex-col lg:flex-row justify-between gap-5">
      <h1 className="text-xl font-semibold">List of Vendors</h1>
      <div className="flex gap-4">
        <div className="border-2 rounded-2xl px-3 py-1 flex justify-between [&>*]:self-center">
          <input type="text" className="bg-inherit focus:outline-none" />
          <FaSearch />
        </div>

        <div className="border-2 rounded-2xl px-5 py-1 flex text-sm justify-between [&>*]:self-center">
          <span>Filter:&nbsp;</span>
          <select className="focus:outline-none">
            <option>All</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default ListHeader
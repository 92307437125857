import axios from "axios";
import {  getAuthToken } from "./auth/auth.service";

// Create an Axios instance with interceptors
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (config.headers) {
        config.headers.Authorization = `Bearer ${getAuthToken()}`;
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default axiosInstance;
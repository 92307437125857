import {
  Flex,
  Image,
  HStack,
  Box,
  Text,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Input,
  Center,
  Link,
} from "@chakra-ui/react";

import congrats from "../../img/msg.svg";

import { useState } from "react";
import authService from "../../services/auth/auth.service";
import { useNavigate } from "react-router-dom";

import AuthLayout from "./AuthLayout";
import UserRoutes from "../routes";

const EmailVerified = () => {

  const route = useNavigate();

  return (
    <>
      <AuthLayout>
        <Box>
          <Flex
            height={"100vh"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
          >
            <Box width={"365px"}>
              <Text
                mb={"60px"}
                fontSize={["20px", "20px", "24px", "24px"]}
                fontWeight="700"
                color="#353535"
                textAlign={"center"}
              >
                Your email has been verified !
              </Text>
              <Flex justifyContent={"center"}>
                <Image src={congrats} />
              </Flex>
              <Box mt={"50px"} mb={"20px"}>
               
                <Text
                  fontSize={["12px", "12px", "12px", "12px"]}
                  fontWeight="400"
                  mb={"40px"}
                  color="#90979E"
                  textAlign={"center"}
                >
                  If you are not immediately redirected to your dashboard,{" "}
                  <br />
                  please click on the link below.
                </Text>
             
                <Box mb={"40px"}>
                  <Flex justifyContent={"center"} mt={"20px"}>
                    <Button
                      type="button"
                      color={"white"}
                      bg={"#583A76"}
                      px={6}
                      size={"md"}
                      onClick={() => {
                      route(UserRoutes.Login)
                      }}
                      fontSize={"14px"}
                    >
                      Login
                    </Button>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      </AuthLayout>
    </>
  );
};

export default EmailVerified;

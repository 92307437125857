import { IVendorsRes } from "../../models/vendors/vendors.model";
import getService from "../crud-requests/getService";

const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL;

class vendorsService {
  async hireVendors(
    categoryId: string,
    serviceId: string
  ): Promise<IVendorsRes> {
    const response = await getService.get(
      `${CONFIG_API_URL}vendors?category_id=${categoryId}&service_id=${serviceId}`
    );
    return response.data;
  }

  async getVendors(): Promise<IVendorsRes> {
    const response = await getService.get(`${CONFIG_API_URL}vendors`);
    return response.data;
  }
}

export default new vendorsService();
